export default `query HomeQuery($locales: [Locale!]!) {
  pdPages(where: { identifier: home }, stage: PUBLISHED, locales: $locales) {
    slug
    title
    content {
      ... on PdSection {
        identifier
        description
        heading
        contentBlock {
          ... on PdBanner {
            multimedia {
              fileName
              url
            }
            multimediaSmall {
              fileName
              url
            }
            video
            title
            description
            link {
              url
            }
          }
          ... on PdNewsItem {
            category {
              name
            }
            id
            dateAndTime
            author
            title
            shortDescription
            mainImage {
              fileName
              url
            }
            externalUrl
          }
        }
        button {
          text
          url
        }
      }
    }
  }
}
`;
