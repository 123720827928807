export default `
query Learn($locales: [Locale!]!) {
  pdPages(where: {identifier: learn}, locales: $locales, stage: PUBLISHED) {
    identifier
    slug
    title
    description {
      text
    }
    answer: content {
      ... on PdSection {
        text: description
      }
    }
    buttons: pdLinks {
      text
      url
    }
  }
}

`;
