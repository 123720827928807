export default `
query EventsQuery($locales: [Locale!]!, $initialDate: DateTime!, $finalDate: DateTime!, $categoriesIds: [ID!], $allEvents: ID!, $first: Int = 3) {
  pdEvents(where: {AND: [{dateAndTime_gt: $initialDate}, {dateAndTime_lt: $finalDate}, 
    {category: { OR: [{id_in: $categoriesIds}, {id_contains: $allEvents}] }}]}, first: $first, locales: $locales, stage: PUBLISHED) {
      title
      description
      dateAndTime
      category {
        name
      }
  }
}
`;
