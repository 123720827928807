export default `
query FinancialInformation($locales: [Locale!]!) {
    pdPages(where: {identifier: financial_information}, locales: $locales, stage: PUBLISHED) {
      identifier
      slug
      title
      content {
        ... on PdSection {
          identifier
          heading
          contentBlock {
            ... on PdBanner {
              title
              description
              multimedia {
                fileName
                url
              }
              multimediaSmall {
                fileName
                url
              }
            }
          }
        }
      }
    }
  }
`;
