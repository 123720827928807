import PropTypes from 'prop-types';

import { colorTypes, placements, borderRadius } from '@config/constants';

export const childrenProps = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.string,
  PropTypes.number,
  PropTypes.object,
  PropTypes.element
]);

export const colorTypeProps = PropTypes.oneOf(Object.values(colorTypes));

export const borderRadiusProps = PropTypes.oneOf(Object.values(borderRadius));

export const placementProps = PropTypes.oneOf(Object.values(placements));

export const inputOptionsProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
});
