import validateField from '@utils/validations';

const serializeModal = ({ title, subtitle, body: { text }, links, logo }) => ({
  title: validateField(title),
  subtitle: validateField(subtitle),
  description: validateField(text),
  buttons: validateField(links),
  imagen: validateField(logo)
});

const modalSerializer = async (request) => {
  const { pdModals } = await request;
  return pdModals[0] ? serializeModal(pdModals[0]) : { buttons: [] };
};

export default modalSerializer;
