import validateField from '@utils/validations';
import { replaceCharacter } from '@utils/strings';

export const serializeContent = async ({ contentBlock }, { slug, locale }) =>
  Promise.all(
    contentBlock?.map(async ({ id: idCanvas, name, content }) => {
      const serializerName = replaceCharacter(name, /_/, '-').toLowerCase();
      const serializer = (
        await import(
          `./canvas-components/${serializerName}-serializer.js`
        ).catch(() => ({ default: (data) => Promise.resolve(data) }))
      ).default;
      const serializedData = await serializer(content, {
        slug,
        locale,
        idCanvas
      });

      return {
        name: validateField(name),
        props: validateField(serializedData)
      };
    })
  );

const canvasSerializer = async (request) => {
  const { pdPages } = await request;
  const page = pdPages[0];
  let result = null;
  const { identifier, content, isCanvas } = page;
  if (page && isCanvas) {
    const restContent = await Promise.all(
      content?.map(async (actualContent) => {
        const serializedContent = await serializeContent(actualContent, {
          slug: page?.slug,
          locale: page?.locale
        });
        return {
          section: {
            title: validateField(actualContent?.heading),
            description: validateField(actualContent?.description)
          },
          content: validateField(serializedContent)
        };
      })
    );
    result = {
      identifier: validateField(identifier),
      isCanvas: validateField(isCanvas),
      content: validateField(restContent)
    };
  }
  return result;
};

export default canvasSerializer;
