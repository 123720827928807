import getTooltip from './get-tooltip';
import getHeaderLogin from './get-header-login';
import getModalProfile from './get-modal-profile';

export default `query HomeQuery($locales: [Locale!]!, $identifier: PdPageIdentifier!) {
  pdPages(
    where: { identifier: $identifier }
    locales: $locales
    stage: PUBLISHED
  ) {
    slug
    title
    seo {
      metaTitle
      metaDescription
      noIndex
      ogImage {
        fileName
      }
    }
  }
  ${getTooltip}
  ${getHeaderLogin}
  ${getModalProfile}
  pdSections(
    locales: $locales
    where: {
      identifier_in: [
        "quick_links"
        "accessibility_bar"
        "newsletter"
        "footer"
      ]
    }
    stage: PUBLISHED
  ) {
    identifier
    contentBlock {
      ... on PdQuickLink {
        links {
          icon {
            fileName
            url
          }
          text
          url
        }
      }
      ... on PdAccessibilityBar {
        lightModeText
        darkModeText
        fontSizeLetter
        customerService {
          text
          url
          icon {
            fileName
            url
          }
        }
        languages {
          flag {
            fileName
            url
          }
          text
        }
        loginText
      }
      ... on PdNewsletter {
        title
        inputLabel
        inputPlaceholder
        termsAndConditionsLabel
        termsAndCondition {
          title
          description {
            html
          }
        }
      }
      ... on PdFooter {
        proceduresAndCertificates {
          title
          links {
            text
            url
            isExternalLink
          }
        }
        interesSites {
          title
          links {
            text
            url
            isExternalLink
          }
        }
        termsAndConditions {
          title
          links {
            text
            url
            isExternalLink
          }
        }
        othersLinks {
          text
          url
          isExternalLink
        }
        socialMedia {
          title
          links {
            url
            icon {
              fileName
              url
            }
          }
        }
        logosAndCertifications {
          logos {
            icon {
              fileName
              url
            }
            typeLogo
            url
            isExternalLink
          }
        }
        copyright
      }
    }
  }
}`;
