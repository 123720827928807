const getReportsByCategoryAndDate = ({ date }) => `
query ReportsAndBulletinsByCategoryAndDate($categoryId: ID!${
  date ? ', $date: Date!' : ''
}, $locales: [Locale!]!) {
  pdReportsAndBulletins(where: {category: {id: $categoryId}${
    date ? ', date: $date' : ''
  }}, stage: PUBLISHED, locales: $locales, orderBy: date_DESC, first:1000) {
    date
    title
    description
    attached {
      fileName
      url
    }
  }
}
`;

export default getReportsByCategoryAndDate;
