import seoSerializer from './seo-serializer';
import validateField from '@utils/validations';

const serializeContent = (content) => {
  const filteredData = content.reduce((data, { identifier, contentBlock }) => {
    if (identifier === 'banner') {
      const [{ titleBanner, multimedia, multimediaSmall, descriptionBanner }] =
        contentBlock;
      data.banner = {
        title: validateField(titleBanner),
        description: validateField(descriptionBanner),
        variableImage: {
          desktop: validateField(multimedia?.url),
          mobile: validateField(multimediaSmall?.url)
        }
      };
    }
    if (identifier === 'banner_secondary') {
      const [
        { titleBanner, multimedia, descriptionBanner, buttonText, buttonUrl }
      ] = contentBlock;
      data.bannerSecondary = {
        title: validateField(titleBanner),
        description: validateField(descriptionBanner),
        image: validateField(multimedia?.url),
        button: {
          text: validateField(buttonText),
          url: validateField(buttonUrl)
        }
      };
    }
    if (identifier === 'content_page') {
      const [{ description, disclaimer, pdTabs, pdIndices }] = contentBlock;
      data.content = {
        description: validateField(description),
        disclaimer: validateField(disclaimer),
        tabs: pdTabs.map(({ seo, ...rest }) => ({
          seo: seoSerializer({ seo }),
          ...rest
        })),
        columns: pdIndices.map(({ fieldValue: field, description: title }) => ({
          field,
          title
        }))
      };
    }
    return data;
  }, {});

  return filteredData;
};

const listOfETFsIssuersSerializer = async (request) => {
  const { pdPages } = await request;
  const [{ content }] = pdPages;

  return serializeContent(content);
};

export default listOfETFsIssuersSerializer;
