import validateField from '@utils/validations';

const bannerSerializer = (banner) => {
  const {
    title,
    description,
    multimedia,
    multimediaSmall,
    buttonText,
    buttonUrl
  } = banner || {};
  return {
    topBanner: {
      image: {
        small: validateField(multimediaSmall?.url),
        large: validateField(multimedia?.url)
      },
      title: validateField(title),
      description: validateField(description),
      button: {
        text: validateField(buttonText),
        url: validateField(buttonUrl)
      }
    }
  };
};

const basketSerializer = (baskets) => ({
  indexes: {
    baskets:
      baskets?.map(({ id, basketTitle, description, attached }) => ({
        description: validateField(description),
        title: validateField(basketTitle),
        id: validateField(id),
        file: {
          url: validateField(attached?.url),
          fileName: validateField(attached?.fileName)
        }
      })) || []
  }
});

const secondaryBannerSerializer = (banner) => {
  const {
    title,
    description,
    multimedia,
    multimediaSmall,
    buttonText,
    buttonUrl
  } = banner || {};
  return {
    bottomBanner: {
      image: {
        small: validateField(multimediaSmall?.url),
        large: validateField(multimedia?.url)
      },
      title: validateField(title),
      description: validateField(description),
      button: {
        text: validateField(buttonText),
        url: validateField(buttonUrl)
      }
    }
  };
};

const indexN3SubcontentSerializer = async (request) => {
  const { pdIndexSubContent } = (await request) || {};
  
  return {
    subMarket: validateField(pdIndexSubContent?.subMarket),
    showHistorySection: validateField(pdIndexSubContent?.showHistorySection),
    showGraphics: validateField(pdIndexSubContent?.showGraphics),
    ...basketSerializer(pdIndexSubContent?.baskets),
    ...bannerSerializer(pdIndexSubContent?.topBanner),
    ...secondaryBannerSerializer(pdIndexSubContent?.bottomBanner)
  };
};

export default indexN3SubcontentSerializer;
