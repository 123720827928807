import seoSerializer from './seo-serializer';

const subTabsSerializer = (subTabs) =>
  subTabs.map(
    ({ button, captions, description, identifier, indices, summary, seo }) => ({
      button,
      captions,
      identifier,
      columnNames: indices,
      title: description,
      summary,
      seo: seoSerializer({ seo })
    })
  );

const localMarketTabsSerializer = async (request) => {
  const { pdTabs } = await request;
  const content = pdTabs.map(
    ({
      subTabs,
      description,
      identifier,
      seo,
      indicesHighlightables,
      generalDescription
    }) => ({
      identifier,
      title: description,
      generalDescription,
      seo: seoSerializer({ seo }),
      subTabs: subTabsSerializer(subTabs),
      indicesHighlightables
    })
  );
  return content;
};

export default localMarketTabsSerializer;
