import moment from 'moment';

import {
  formatDecimal,
  generalFormatDecimal,
  roundToTwo
} from '@utils/numbers';

export const serializeTableFieldDerivates = (value, displaySerializer) => {
  if (value === 0) {
    return '0';
  }
  if (!value && value !== 0) {
    return '-';
  }
  return {
    value,
    display: displaySerializer()
  };
};
export const serializeTableField = (
  value,
  displaySerializer,
  valueSerializer = (e) => e
) => {
  if (!value && value !== 0) {
    return '-';
  }
  return displaySerializer
    ? {
        value: valueSerializer(value),
        display: displaySerializer(value)
      }
    : valueSerializer(value);
};

export const formatDate = (date) =>
  date ? moment(date).format('YYYY-MM-DD') : null;

export const defaultNumberFormat = (number, params = {}, decimalsNumber = 2) =>
  number && number !== 0
    ? formatDecimal(number, {
        separator: ',',
        decimal: '.',
        decimals: decimalsNumber,
        ...params
      })
    : 0;

export const defaultVariationFormat = (number, showPercentage = true) =>
  `${generalFormatDecimal(number, {
    separator: ',',
    decimal: '.',
    decimals: 2
  })}${showPercentage ? '%' : ''}`;

export const variationFormatWithDecimals = (num, showPercentage = true) => {
  const { value, decimals } = roundToTwo(num);
  return `${formatDecimal(value, {
    separator: ',',
    decimal: '.',
    decimals
  })}${showPercentage ? '%' : ''}`;
};

export const formatColumnNames = (columnNames = []) =>
  columnNames?.reduce((acc, { description, fieldIdentifier }) => {
    acc[fieldIdentifier] = description;
    return acc;
  }, {});

export const getColumns = (columnNames = [], blacklist = []) =>
  columnNames?.reduce((acc, { description, fieldIdentifier }) => {
    if (!blacklist.includes(fieldIdentifier)) {
      acc.push({
        field: fieldIdentifier,
        title: description
      });
    }
    return acc;
  }, []);

export const serializeReport = (r) => ({
  ...r,
  date: moment(r.date).format('YYYY/MM/DD')
});

export const serializeStock = ({ title, value, percentage } = {}) => ({
  title,
  value: defaultNumberFormat(value),
  percentage: percentage || 0
});

export const getNameEmisor = (name, isMobile) => {
  const maxLength = isMobile ? 25 : 35;
  if (name?.length > maxLength) {
    let result = name.substr(0, maxLength);
    result = result.substr(0, Math.min(result.length, result.lastIndexOf(' ')));
    const restName = name.substr(result.length + 1);
    return `${result}<br>${getNameEmisor(restName, isMobile)}`;
  }
  return name;
};

// Return fields based on fieldIdentifier on new Filters based on graphql
export const checkField = (columnData) => {
  if (columnData.fieldIdentifier === 'symbol') {
    return 'mnemonic';
  } else if (columnData.fieldIdentifier) {
    return columnData.fieldIdentifier;
  }
};
