const objUrls = {
  en: {
    index_stock: 'indices_accionarios',
    'index_fixed-income': 'indices_renta-fija',
    'index_money-market': 'indices_mercado-monetario',
    'equity_local-market': 'renta-variable_mercado-local',
    'equity_colombian-global-market':
      'renta-variable_mercado-global-colombiano',
    'fixed-income_public-debt-public-segment':
      'renta-fija_deuda-publica-segmento-publico',
    'fixed-income_public-debt-private-segment':
      'renta-fija_deuda-publica-segmento-privado',
    'fixed-income_corporate-debt': 'renta-fija_deuda-corporativa',
    derivatives_futures: 'derivados_futuros',
    derivatives_options: 'derivados_opciones',
    'foreign-exchange_spot-market': 'divisas_mercado-spot'
  },
  es: {
    indices_accionarios: 'index_stock',
    'indices_renta-fija': 'index_fixed-income',
    'indices_mercado-monetario': 'index_money-market',
    'renta-variable_mercado-local': 'equity_local-market',
    'renta-variable_mercado-global-colombiano':
      'equity_colombian-global-market',
    'renta-fija_deuda-publica-segmento-publico':
      'fixed-income_public-debt-public-segment',
    'renta-fija_deuda-publica-segmento-privado':
      'fixed-income_public-debt-private-segment',
    'renta-fija_deuda-corporativa': 'fixed-income_corporate-debt',
    derivados_futuros: 'derivatives_futures',
    derivados_opciones: 'derivatives_options',
    'divisas_mercado-spot': 'foreign-exchange_spot-market'
  }
};

export default objUrls;
