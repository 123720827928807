const getFinancialInformationByCategoryAndDate = ({ dateStart, dateEnd }) => {
  const isFilteredByDate = dateStart && dateEnd;
  const dynamicParams = isFilteredByDate
    ? '$dateStart: Date!, $dateEnd: Date!,'
    : '';
  const dynamicFilters = isFilteredByDate
    ? ', date_gte: $dateStart, date_lte: $dateEnd'
    : '';
  return `query FinancialInformationByCategoryAndDate($categoryId: ID!, ${dynamicParams} $locales: [Locale!]!) {
    pdFinancialInformations(where: { category: { id: $categoryId } ${dynamicFilters} }, stage: PUBLISHED, locales: $locales, orderBy: date_DESC) {
      id
      date
      title
      description
      attached {
        id
        fileName
        url
      }
    }
  }
`;
};

export default getFinancialInformationByCategoryAndDate;
