export default `
query NotFound($locales: [Locale!]!) {
  pdPages(where: {identifier: error_404}, locales: $locales, stage: PUBLISHED) {
    identifier
    slug
    title
    description {
      html
    }
    buttons: pdLinks {
      text
      url
    }
  }
}
`;
