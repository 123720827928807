export default `query getFavicon() {
    pdFavicons {
      image {
        url
      }
      vectorImage {
        url
      }
    }
  }
  `;
  