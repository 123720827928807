import seoSerializer from './seo-serializer';
import validateField from '@utils/validations';
import { identifiersRV } from '@config/constants';
import { dashedCaseToCamel } from '@utils/strings';

const { marketGlobalIssuer } = identifiersRV;

const findTab = (marketLevel, tabs = []) =>
  tabs.findIndex(({ id }) => id === marketLevel);

const serializeContent = (content) => {
  const filteredData = content.reduce(
    (
      data,
      {
        identifier,
        heading,
        description,
        tab,
        contentBlock = [{}],
        pdLinks = [],
        button = {}
      }
    ) => {
      if (identifier === marketGlobalIssuer.banner_main) {
        const [{ multimedia, multimediaSmall, buttonText, buttonUrl }] =
          contentBlock;
        const {
          description: descriptionIssuer,
          issuerCompany,
          logo
        } = content.find(
          ({ identifier: id }) => id === marketGlobalIssuer.info_participant
        )?.contentBlock[0] || {};

        data.banner_main = {
          title: validateField(issuerCompany),
          logo: validateField(logo?.url),
          description: validateField(descriptionIssuer),
          button: {
            text: validateField(buttonText),
            url: validateField(buttonUrl)
          },
          image: {
            large: validateField(multimedia?.url),
            small: validateField(multimediaSmall?.url)
          }
        };
      }
      if (
        identifier === marketGlobalIssuer.banner_bvc ||
        identifier === marketGlobalIssuer.banner_secondary
      ) {
        const [
          {
            multimediaSmall,
            description: descriptionBanner,
            multimedia,
            buttonText,
            buttonUrl,
            title
          }
        ] = contentBlock;
        data[dashedCaseToCamel(identifier)] = {
          title: validateField(title),
          description: validateField(descriptionBanner),
          image: {
            large: validateField(multimedia?.url),
            small: validateField(multimediaSmall?.url)
          },
          button: {
            text: validateField(buttonText),
            url: validateField(buttonUrl)
          }
        };
      }
      if (
        tab &&
        (identifier === marketGlobalIssuer.information_issuer ||
          identifier === marketGlobalIssuer.information_general ||
          identifier === marketGlobalIssuer.information_operations ||
          identifier === marketGlobalIssuer.information_operations_contado ||
          identifier === marketGlobalIssuer.information_operations_repo ||
          identifier === marketGlobalIssuer.information_operations_ttv ||
          identifier === marketGlobalIssuer.info_summary ||
          identifier === marketGlobalIssuer.prices_and_volumes ||
          identifier === marketGlobalIssuer.fundamental ||
          identifier === marketGlobalIssuer.stock_information ||
          identifier === marketGlobalIssuer.historics)
      ) {
        const { description: descriptionTab, marketLevel, seo } = tab;
        const indexTab = findTab(marketLevel, data.tabs);
        const buttons =
          identifier === marketGlobalIssuer.information_general
            ? content.find(
                ({ identifier: id }) =>
                  id === marketGlobalIssuer.info_participant
              )?.contentBlock?.pdLinks
            : pdLinks;
        const [sectionContent] = contentBlock;
        const sponsors =content.find((contents)=>contents?.identifier==marketGlobalIssuer.info_participant)?.contentBlock || []
        const section = {
          title: validateField(heading),
          description: validateField(description),
          showSummaryGraphic: validateField(sectionContent?.showSummaryGraphic),
          secondaryDescription: validateField(sectionContent?.description),
          content: contentBlock,
          indices: validateField(sectionContent?.pdIndices),
          captions:
            contentBlock[0]?.captions.map(({ description: label }) =>
              validateField(label)
            ) || [],
          buttons,
          button
        };
        indexTab >= 0
          ? (data.tabs[indexTab].sections[identifier] = section)
          : data.tabs.push({
              id: marketLevel,
              title: descriptionTab,
              sections: {  [identifier]: identifier==marketGlobalIssuer.information_issuer?{...section,sponsors}:section },
              seo: seoSerializer({ seo })
            });
      }
      return data;
    },
    { tabs: [] }
  );

  return filteredData;
};

const rvMarketGlobalIssuerSerializer = async (request) => {
  const { pdPages, pdContentParticipants = [] } = await request;
  const [{ content }] = pdPages;
  const issuer = pdContentParticipants;
  content.push({
    contentBlock: issuer,
    identifier: marketGlobalIssuer.info_participant
  });
  return serializeContent(content);
};

export default rvMarketGlobalIssuerSerializer;
