export default `query NewsItemQuery($id: ID!, $locales: [Locale!]! ) {
    pdNewsItem(where: {id: $id}, locales: $locales, stage: PUBLISHED) {
        slug
        mainImage {
            fileName
            url
        }
        title
        author
        dateAndTime
        socialMedia {
            title
            links {
            icon {
                fileName
                url
            }
            url
            }
        }
        shortDescription
        image1 {
            fileName
            url
        }
        paragraph1
        subtitle
        paragraph2
        attachedFile {
            fileName
            url
        }
        image2 {
            fileName
            url
        }
        otherContent {
            html
        }
        pdSection(skip: 1) {
            heading
            identifier
            contentBlock {
            ... on PdNewsItem {
                id
                slug
                title
                author
                dateAndTime
                attachedFile {
                    fileName
                    url
                }
            }
            }
        }
        category {
            identifier
        }
        seo {
            metaDescription
            metaTitle
            noIndex
            ogImage {
              url
            }
        }
    }
  }
`;
