import validateField from '@utils/validations';

const newsSerializer = async (request) => {
  const { pdNews } = await request;
  const content = pdNews.map(
    ({ author, dateAndTime, id, title, shortDescription, mainImage }) => ({
      id,
      author,
      title,
      description: shortDescription,
      date: dateAndTime,
      image: validateField(mainImage?.url)
    })
  );
  return content;
};

export default newsSerializer;
