const eventsSerializer = async (request) => {
  const { pdEvents } = await request;

  const events = pdEvents.map(
    ({ title, description, dateAndTime, category }) => ({
      name: title,
      description,
      type: category.name,
      date: dateAndTime
    })
  );

  return events;
};

export default eventsSerializer;
