export default `query IssuersListGlobalQuery($locales: [Locale!]!) {
  pdPages(where: { identifier: issuers_list_global_market }, stage: PUBLISHED, locales: $locales) {
    slug
    title
    content {
      ... on PdSection {
        identifier
        contentBlock {
           ... on PdBanner {
            id
            video
            title
            description
            multimedia{
              url
            }
            buttonText
            buttonUrl
          }        
        }
      }
    }
  }
}
`;
