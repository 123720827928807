export default `
query getNonTradingDaysPage($locales: [Locale!]!) {
  pdPages(where: {identifier: non_trading_days }, stage: PUBLISHED, locales: $locales) {
    description {
      text
    }
    content {
      ... on PdSection {
        heading
        identifier
        contentBlock {
          ... on PdBanner {
            titleBanner: title
            descriptionBanner: description
            multimedia {
              url
            }
            multimediaSmall {
              url
            }
          }
          ... on PdCalendarDownloadCard {
            title
            description
            date
            file {
              url
              fileName
            }
          }
        }
      }
    }
  }
}
`;
