export default `query InstallersAndHandbooksQuery($locales: [Locale!]!) {
  pdPages(where: { identifier: installers_and_handbooks }, stage: PUBLISHED, locales: $locales) {
    slug
    title
    content {
      ... on PdSection {
        identifier
        contentBlock {
           ... on PdBanner {
            id
            video
            title
            description
            multimedia{
              url
            }
          } 
          ... on PdInstallersAndHandbooksTab {
            id
            categories:pdInstallersAndHandbooksCategories {
              id
              title
              text
              seo {
                metaTitle
                metaDescription
                noIndex
                ogImage {
                  fileName
                }
              }
              cards:pdInstallersAndHandBooksCards(
                orderBy: date_DESC
              ) {
                id
                date
                title
                description
                file {
                  url,
                  fileName
                }
              }
            }
          }
          ... on PdGeneralContent {
            id
            generalContentTitle: title
            cards:pdGeneralContentCards(
              orderBy: order_ASC
            ) {
              id
              date
              title
              description
              file {
                url,
                fileName
              }
            }
          }          
        }
      }
    }
  }
}
`;
