import { sortArrayObjAlphabetically } from '@utils/arrays';
import { canvasComponentsIdentifiers } from '@config/constants';

const glossarySerializer = async (request) => {
  const { pdGlossaries } = await request;
  const items = pdGlossaries.reduce(
    (
      finalItems,
      {
        pdLinks,
        cardIcon,
        titleCard,
        containCard,
        componentType,
        pdGlossaryCategory
      }
    ) => {
      if (componentType === canvasComponentsIdentifiers.glossary) {
        const content = { title: titleCard || null };
        if (containCard) {
          content.richText = containCard.html || null;
        }
        if (cardIcon) {
          content.icon = cardIcon.url || null;
        }
        if (pdLinks?.length) {
          content.links = pdLinks.reduce((acc, link) => {
            if (link.pdPopup) {
              link.modalDownload = link.pdPopup;
            }
            acc.push(link);
            return acc;
          }, []);
        }
        if (pdGlossaryCategory) {
          content.category = pdGlossaryCategory.name || null;
        }
        finalItems.push(content);
      }
      return finalItems;
    },
    []
  );

  return sortArrayObjAlphabetically(items, 'category');
};

export default glossarySerializer;
