const filtersMarketLvl2Serializer = async (request) => {
  const { pdFiltersLvl2 = [] } = await request;
  const groupMarketLvl2 = pdFiltersLvl2.reduce((acc, curr) => {
    const { tabIdentifier, subTabIdentifier, indices } = curr;
    if (!acc[tabIdentifier]) {
      acc[tabIdentifier] = {
        [subTabIdentifier]: indices
      };
    } else if (acc[tabIdentifier] && !acc[tabIdentifier][subTabIdentifier]) {
      acc[tabIdentifier][subTabIdentifier] = indices;
    }
    return acc;
  }, {});
  return groupMarketLvl2;
};

export default filtersMarketLvl2Serializer;
