import validateField from '@utils/validations';

const operationalEmergingRiskReportsSerializer = async (request) => {
  const { pdOperationalEmergingRiskReports = [] } = await request;
  return pdOperationalEmergingRiskReports.map((report) => ({
    id: validateField(report?.id),
    date: validateField(report?.date),
    attached: {
      id: validateField(report?.attached?.id),
      fileName: validateField(report?.attached?.fileName),
      url: validateField(report?.attached?.url)
    },
    title: validateField(report?.title),
    description: validateField(report.description)
  }));
};

export default operationalEmergingRiskReportsSerializer;
