export default `
query PageContent($identifier: PdPageIdentifier!, $locales: [Locale!]!) {
  pdPages(where: {identifier: $identifier}, locales: $locales, stage: PUBLISHED) {
    identifier
    slug
    title
    description {
      text
    }
    content {
      ... on PdSection {
        identifier
        heading
        contentBlock {
          ... on PdBanner {
            title
            description
            buttonText
            buttonUrl
            multimedia {
              fileName
              url
            }
            multimediaSmall {
              fileName
              url
            }
          }
        }
      }
    }
  }
}
`;
