export default `query MyQuery($locales: [Locale!]!) {
  pdPages(where: {identifier: customer_service}, locales: $locales, stage: PUBLISHED) {
    identifier
    slug
    title
    description {
      html
    }
    content {
      ... on PdSection {
        identifier
        heading
        contentBlock {
          ... on PdService {
            title
            description
            image {
              url
              fileName
            }
            button {
              text
              url
            }
          }
          ... on PdOurHeadquarter {
            name
            address
            phone
            image {
              fileName
              url
            }
          }
        }
      }
    }
  }
}`;
