export default `
query ModalQuery($locales: [Locale!]!, $identifier: String, $marketLevel: String) {
  pdModals(where: {identifier: $identifier, marketLevel: $marketLevel}, locales: $locales, stage: PUBLISHED) {
    title
    subtitle
    body {
      text
    }
    links {
      text
    }
    logo {
      ... on PdLink {
        icon {
          fileName
          url
        }
      }
    }
  }
}
  
`;
