const eventCategoriesSerializer = async (request) => {
  const { pdEventCategories } = await request;
  const eventCategories = pdEventCategories.map(({ id, name }) => ({
    label: name,
    value: id
  }));
  return eventCategories;
};

export default eventCategoriesSerializer;
