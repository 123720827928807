/* istanbul ignore file */
import { types } from '@config/constants';

export default {
  apis: {
    etfs: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_RV_REST,
      endpoints: {
        getOptions: {
          method: 'get',
          uri: '/market-information/rv/list-actions/options'
        }
      }
    },
    pqr: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_TICKER_REST,
      endpoints: {
        sendMessage: {
          method: 'post',
          uri: 'pqr-send'
        }
      }
    },
    bvcStock: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_RV_REST,
      endpoints: {
        get: {
          method: 'get',
          uri: 'bvc-stock'
        }
      }
    },
    [types.RV]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_RV_REST,
      endpoints: {
        getSummaryLvl3: {
          method: 'get',
          uri: 'market-information/rv/lvl-3/summary?{params.filters}'
        },
        getBoxIssuer: {
          method: 'get',
          uri: 'market-information/rv/lvl-3/box?{params.filters}'
        },
        getInfoIssuer: {
          method: 'get',
          uri: 'market-information/rv/lvl-3/issuer-type/{params.filters}'
        },
        getParticipant: {
          method: 'get',
          uri: 'market-information/rv/lvl-3/issuer/{params.filters}'
        },
        getIssuers: {
          method: 'get',
          uri: 'market-information/rv/lvl-3/issuer?{params.filters}'
        },
        getMarketInformation: {
          method: 'get',
          uri: 'market-information/rv/lvl-1'
        },
        getMarketInformationLvl2: {
          method: 'get',
          uri: 'market-information/rv/lvl-2?{params.filters}'
        },
        getOperationsTypesLvl2: {
          method: 'get',
          uri: 'market-information/rv/lvl-2/market-rv'
        },
        getListOptions: {
          method: 'get',
          uri: 'market-information/rv/list-actions?{params.filters}'
        },
        getMarketInformationLvl3: {
          method: 'get',
          uri: 'market-information/rv/lvl-3/operation?{params.filters}'
        },
        getStockList: {
          method: 'get',
          uri: 'market-information/rv/lvl-3/stock-list?{params.filters}'
        },
        getGraphicLvl3: {
          method: 'get',
          uri: 'market-information/rv/lvl-3/summary/graphic?{params.filters}'
        }
      }
    },
    [types.INDEXES]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_INDEXES_REST,
      endpoints: {
        getFixedRentTab: {
          method: 'get',
          uri: 'market-information/index/lvl-1/rf-pub'
        },
        getMoneyMarketTab: {
          method: 'get',
          uri: 'market-information/index/lvl-1/col-ibr'
        },
        getStockholders: {
          method: 'get',
          uri: 'market-information/index/lvl-1/rv'
        },
        getStockholdersLvl2: {
          method: 'get',
          uri: 'market-information/index/lvl-2/rv?{params.filters}'
        },
        getFixedRentLvl2: {
          method: 'get',
          uri: 'market-information/index/lvl-2/rf-pub?{params.filters}'
        },
        getMoneyMarketLvl2: {
          method: 'get',
          uri: 'market-information/index/lvl-2/col-ibr?{params.filters}'
        },
        getSummaryN3: {
          method: 'get',
          uri: '/market-information/index/lvl-3/summary?{params.filters}'
        },
        getGraphicLvl3: {
          method: 'get',
          uri: '/market-information/index/lvl-3/graphic?{params.filters}'
        }
      }
    },
    [types.RF_PUB]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_RF_PUB_REST,
      endpoints: {
        getMarketInformation: {
          method: 'get',
          uri: 'market-information/rf/lvl-1'
        },
        getMarketInformationLvl2: {
          method: 'get',
          uri: 'market-information/rf/lvl-2?{params.filters}'
        },
        getBoardOptionsLvl2: {
          method: 'get',
          uri: 'market-information/rf/lvl-2/board-rf-pub?{params.filters}'
        },
        getSecurityOptionsLvl2: {
          method: 'get',
          uri: 'market-information/rf/lvl-2/security-rf-pub'
        },
        getRfPrivOptionsLvl2: {
          method: 'get',
          uri: 'market-information/rf/lvl-2/corp/filters'
        },
        getMarketInformationLvl3: {
          method: 'get',
          uri: 'market-information/rf/lvl-3/operation?{params.filters}'
        },
        getBoxIssuer: {
          method: 'get',
          uri: 'market-information/rf/lvl-3/box?{params.filters}'
        },
        getSummaryN3: {
          method: 'get',
          uri: 'market-information/rf/lvl-3/summary?{params.filters}'
        },
        getGraphicN3: {
          method: 'get',
          uri: 'market-information/rf/lvl-3/summary/graphic?{params.filters}'
        }
      }
    },
    [types.DER]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_DER_REST,
      endpoints: {
        getMarketInformation: {
          method: 'get',
          uri: 'market-information/der/lvl-1'
        },
        getMarketInformationLvl2: {
          method: 'get',
          uri: 'market-information/der/lvl-2?{params.filters}'
        },
        getInstrumentTypes: {
          method: 'get',
          uri: 'market-information/der/lvl-2/filterOptions?{params.filters}'
        },
        getOptionsTypes: {
          method: 'get',
          uri: 'market-information/der/lvl-2/filterTypeOptions'
        },
        getOperations: {
          method: 'get',
          uri: 'market-information/der/lvl-3/operations/{params.filters}'
        },
        getGraphicLvl3: {
          method: 'get',
          uri: 'market-information/der/lvl-3/graphic?{params.filters}'
        },
        getSummaryN3: {
          method: 'get',
          uri: '/market-information/der/lvl-3/summary?{params.filters}'
        }
      }
    },
    ticker: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_TICKER_REST,
      endpoints: {
        getTicker: { method: 'get', uri: 'ticker' },
        getHistorical: {
          method: 'get',
          uri: 'market-history?{params.filters}'
        },
        getTime: {
          method: 'get',
          uri: 'time'
        }
      }
    },
    [types.DIV]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_DIV_REST,
      endpoints: {
        getMarketInformation: {
          method: 'get',
          uri: 'market-information/div/lvl-1'
        },
        getMarketInformation2: {
          method: 'get',
          uri: 'market-information/div/lvl-2'
        },
        getOperationsLvl2: {
          method: 'get',
          uri: '/market-information/div/lvl-2/registry-operations-div?{params.filters}'
        },
        getTradingDay: {
          method: 'get',
          uri: 'market-information/div/trading-day?{params.filters}'
        },
        getInternationalTradingDay: {
          method: 'get',
          uri: 'market-information/div/international-trading-day?{params.filters}'
        },
        getNonTradingDays: {
          method: 'get',
          uri: 'market-information/div/non-trading-days?{params.filters}'
        }
      }
    }
  },
  websockets: {
    variableIncome: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_RV,
      events: {
        bvcActionDelay: 'delay'
      }
    },
    [types.RV]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_RV,
      events: {
        MarketInformation1RV: 'MarketInformation1RV',
        MarketInformation3RV: 'MarketInformation3RV',
        TableGraphicInformation3RV: 'TableGraphicInformation3RV'
      }
    },
    [types.INDEXES]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_INDEXES,
      events: {
        MarketInformationLvl1IndexesColIbr:
          'MarketInformationLvl1IndexesColIbr',
        MarketInformationLvl1IndexesRv: 'MarketInformationLvl1IndexesRv',
        MarketInformationLvl1IndexesRfPub: 'MarketInformationLvl1IndexesRfPub',
        MarketInformationLvl3IndexesRv: 'MarketInformationLvl3IndexesRv',
        MarketInformationLvl3IndexesRfPub: 'MarketInformationLvl3IndexesRfPub',
        MarketInformationLvl3IndexesColIbr: 'MarketInformationLvl3IndexesColIbr'
      }
    },
    [types.RF_PUB]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_RF_PUB,
      events: {
        MarketInformationRfPub: 'MarketInformationRf',
        MarketInformation3Rf: 'MarketInformation3Rf',
        TableGraphicInformation3Rf: 'TableGraphicInformation3Rf'
      }
    },
    [types.DER]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_DER,
      events: {
        MarketInformationDer: 'MarketInformationDer',
        MarketInformationDerLvl3: 'MarketInformationDerLvl3'
      }
    },
    [types.DIV]: {
      baseURL: process.env.NEXT_PUBLIC_ORCHESTRATOR_URL_DIV,
      events: {
        MarketInformation: 'MarketInformation1DIV'
      }
    }
  },
  cms: {
    endpoint: process.env.NEXT_PUBLIC_CMS_ENDPOINT,
    apiKey: process.env.NEXT_PUBLIC_CMS_APIKEY
  },
  auth: { endpoint: process.env.NEXT_PUBLIC_BVC_BASE_VALIDATE_SESSION },
  behaviorTracker: {
    siteId: process.env.NEXT_PUBLIC_BEHAVIOR_TRACKER_SITE_ID,
    version: process.env.NEXT_PUBLIC_BEHAVIOR_TRACKER_VERSION
  },
  analytics: {
    trackingId: process.env.NEXT_PUBLIC_ANALYTICS_TRACKING_ID
  },
  isProduction: process.env.NODE_ENV === 'production',
  nextCaptchaKey: process.env.NEXT_PUBLIC_CAPTCHA_KEY,
  credentials: {
    username: process.env.NEXT_PUBLIC_AUTH_USERNAME,
    password: process.env.NEXT_PUBLIC_AUTH_PASSWORD,
    secretKey: process.env.NEXT_PUBLIC_AUTH_SECRET_KEY
  }
};
