export default `query MyQuery($locales: [Locale!]!) {
    pdPages(where: {childPage_every: {id_not: ""}, AND: {parentPage_none:{id_not:""}}}, locales: $locales, stage: PUBLISHED) {
      identifier
      slug
      externalUrl
      isExternalLink
      title
      description {
        html
      }
      childPage {
        identifier
        slug
        externalUrl
        isExternalLink
        title
        childPage {
          slug
          externalUrl
          isExternalLink
          title
          childPage {
            id
            slug
            title
            externalUrl
            isExternalLink
          }
        }
      }
    }
  }`;
