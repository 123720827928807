import seoSerializer from './seo-serializer';
import validateField from '@utils/validations';

export const serializeContent = ({ identifier, contentBlock }) => {
  if (!contentBlock.length) return {};
  switch (identifier) {
    case 'installers_and_handbooks_banner': {
      const { title, multimedia, description } = contentBlock[0];
      return {
        banner: {
          title: validateField(title),
          description: validateField(description),
          image: validateField(multimedia?.url)
        }
      };
    }
    case 'installers_and_handbooks_tabs': {
      const { categories } = contentBlock[0];
      return {
        categories: categories.map((category) => ({
          id: validateField(category?.id),
          name: validateField(category?.title),
          description: validateField(category?.text),
          seo: seoSerializer({ seo: category?.seo }),
          cards: category?.cards?.map((card) => ({
            id: validateField(card?.id),
            date: validateField(card?.date),
            title: validateField(card?.title),
            description: validateField(card?.description),
            file: validateField(card?.file)
          }))
        }))
      };
    }
    case 'installers_and_handbooks_general_content': {
      const { id, generalContentTitle, cards } = contentBlock[0];
      return {
        generalContent: {
          id: validateField(id),
          title: validateField(generalContentTitle),
          cards: cards?.map((card) => ({
            id: validateField(card?.id),
            date: validateField(card?.date),
            title: validateField(card?.title),
            description: validateField(card?.description),
            file: validateField(card?.file)
          }))
        }
      };
    }

    default: {
      return { [identifier]: validateField(contentBlock) };
    }
  }
};

const installersAndHandbooksSerializer = async (request) => {
  const { pdPages } = (await request) || {};
  const page = pdPages[0];
  const content = page?.content?.reduce(
    (finalContent, actualContent) => ({
      ...finalContent,
      ...serializeContent(actualContent)
    }),
    {}
  );
  return content;
};

export default installersAndHandbooksSerializer;
