export default `query getFiltersMarket2($tabIdentifier: [PdTabIdentifier], $locales: [Locale!]!) {
  pdFiltersLvl2(where: {tabIdentifier_in: $tabIdentifier}, locales: $locales){
    	tabIdentifier
      subTabIdentifier
      indices {
        description
        fieldValue
        fieldIdentifier
        isHiddenField
      }
  }
}`;
