import { checkField } from '@utils/utilsSerializers';

export const isAnArray = (item) => Array.isArray(item);

export const arrayToObject = (arr, formatter = (i) => i) =>
  arr.reduce((acc, curr) => {
    acc[curr] = formatter(curr);
    return acc;
  }, {});

export const getUniqueElements = (
  arr = [],
  key = undefined,
  serializer = null
) => {
  const filteredArray = arr.reduce((acc, value) => {
    if (typeof serializer === 'function') {
      acc[value[key]] = serializer(value);
    } else {
      acc[value[key]] = value;
    }
    return acc;
  }, {});
  return Object.values(filteredArray);
};

export const removeObjectKeysByPosition = (
  arr,
  positionsToInclude,
  isFilteredColumns = false,
  titles = []
) => {
  return arr.map((row) => {
    const rowToArray = Object.entries(row);
    let rowToArrayFiltered;
    if (!isFilteredColumns) {
      rowToArrayFiltered = rowToArray.filter((_, index) =>
        positionsToInclude.includes(index)
      );
    }
    if (isFilteredColumns) {
      rowToArrayFiltered = rowToArray.filter((key, _) => {
        const listIdentifier = positionsToInclude.map((rowKey) =>
          checkField(rowKey)
        );

        return listIdentifier.includes(key[0]);
      });
    }
    //EXPORTAR CAMPOS ORDENADOS PARA SUBTABS DE RENTA VARIABLE
    let entries = Object.fromEntries(rowToArrayFiltered);
    if (isFilteredColumns) {
      const titlesKey = titles?.map((title) => checkField(title));
      const newEntries = Object.keys(entries).sort(
        (a, b) => titlesKey.indexOf(a) - titlesKey.indexOf(b)
      );
      const data = [];
      newEntries.forEach((key) => {
        data.push([key, entries[key]]);
      });
      entries = Object.fromEntries(data);
    }
    return entries;
  });
};

const getValueOrDisplay = (column) => {
  const columns = ['lastTradeNegotiationDay'];
  return columns.some((item) => item === column);
};
export const getValueFromCsv = (arr) =>
  arr.map((item) => {
    Object.keys(item).forEach((index) => {
      if (typeof item[index] === 'object' && item[index] !== null) {
        item[index] = getValueOrDisplay(index)
          ? item[index].display
          : String(item[index].value).replace('.', ',');
      }
    });
    return item;
  });

// Swap Positions elements in arrays
export const swapElement = (arr, positions) => {
  const [from, to] = positions;
  const copyArr = [...arr];
  const temp = copyArr[from];

  copyArr[from] = copyArr[to];
  copyArr[to] = temp;
  return copyArr;
};

export const generateNumberArray = (length) => Array.from(Array(length).keys());

export const sortArrayObjAlphabetically = (objArray = [], key = undefined) =>
  objArray.sort((firstEl, secondEl) => {
    const textA = firstEl[key]?.toLowerCase();
    const textB = secondEl[key]?.toLowerCase();
    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }
    return 0;
  });

export const divideArray = (arr, divideIn) => {
  if (arr.length) {
    const finalArray = [];
    const slicedLength = Math.ceil(arr.length / divideIn);
    for (let i = 0; i < slicedLength; i++) {
      finalArray.push(arr.slice(divideIn * i, divideIn * (i + 1)));
    }
    return finalArray;
  }
  return [];
};

export const sortArrayByDate = (arr = [], key = 'date') =>
  arr.sort((a, b) => {
    const firstDate = new Date(a[key]);
    const secondDate = new Date(b[key]);
    return secondDate - firstDate;
  });

export const createGroups = (arr = [], groupLenght = 7) =>
  arr.reduce(
    (acc, value) => {
      const lastIndex = acc.length - 1;
      if (acc[lastIndex]?.length >= groupLenght) {
        acc.push([value]);
      } else {
        acc[lastIndex].push(value);
      }
      return acc;
    },
    [[]]
  );
