import validateField from '@utils/validations';

const pageContentSerializer = async (request) => {
  const { pdPages } = await request;
  return (
    pdPages.map((page) => {
      const descriptionPage = page?.description?.text;
      const { identifier, slug, content = [] } = page || {};
      const { contentBlock = [] } = content[0] || {};
      const {
        title,
        description,
        multimedia,
        multimediaSmall,
        buttonText,
        buttonUrl
      } = contentBlock[0] || {};
      return {
        identifier: validateField(identifier),
        descriptionPage: validateField(descriptionPage),
        slug: validateField(slug),
        title: validateField(title),
        description: validateField(description),
        multimediaUrl: validateField(multimedia?.url),
        multimediaSmallUrl: validateField(multimediaSmall?.url),
        buttonText: validateField(buttonText),
        buttonUrl: validateField(buttonUrl)
      };
    })[0] || {}
  );
};

export default pageContentSerializer;
