export default `
  query FinancialInformationCategories($locales: [Locale!]!) {
    pdFinancialInformationCategories(where: {subCategories_every: {id_not: ""}}, locales: $locales, stage: PUBLISHED) {
      id
      indentifier
      name
      description
      seo {
        metaTitle
        metaDescription
        noIndex
        ogImage {
          fileName
        }
      }
      subCategories {
        id
        indentifier
        name
        description
        seo {
          metaTitle
          metaDescription
          noIndex
          ogImage {
            fileName
          }
        }
      }
    }
  }
`;
