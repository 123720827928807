import jwt from 'jwt-simple';
import publicIp from 'public-ip';
import { v4 as uuidv4 } from 'uuid';

import app from '@config/app';

const tokenGenerator = async () => {
  const { username, password, secretKey } = app.config.credentials;
  const payload = {
    id: uuidv4(),
    username,
    password,
    ip: await publicIp.v4(),
    timestamp: new Date().valueOf()
  };

  // Generate unique jwt token
  return jwt.encode(payload, secretKey);
};

export default tokenGenerator;
