import seoSerializer from './seo-serializer';
import validateField from '@utils/validations';

const formatHtmlText = (text) =>
  text ? text.replace(/<p><\/p>/g, '<br />') : null;

const operationalEmergingRiskCategorySerializer = async (request) => {
  const { pdOperationalEmergingRiskCategories } = await request;
  return pdOperationalEmergingRiskCategories.map((category) => ({
    id: validateField(category?.id),
    name: validateField(category?.name),
    reports: validateField(category.pdOperationalEmergingRisks),
    description: formatHtmlText(category?.description?.html),
    bulletTitle1: validateField(category?.bulletTitle1),
    bulletTitle2: validateField(category?.bulletTitle2),
    bulletDescription1: validateField(category?.bulletDescription1),
    bulletDescription2: validateField(category?.bulletDescription2),
    seo: seoSerializer({ seo: category?.seo })
  }));
};

export default operationalEmergingRiskCategorySerializer;
