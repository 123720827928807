import validateField from '@utils/validations';

const reportAndBuleltinSerializer = async (request) => {
  const { pdPages } = await request;
  return pdPages.map((page) => {
    const { identifier, slug, content = [] } = page || {};
    const { contentBlock = [] } = content[0] || {};
    const { title, description, multimedia, multimediaSmall } =
      contentBlock[0] || {};
    return {
      identifier: validateField(identifier),
      slug: validateField(slug),
      title: validateField(title),
      description: validateField(description),
      multimediaUrl: validateField(multimedia?.url),
      multimediaSmallUrl: validateField(multimediaSmall?.url)
    };
  })[0];
};

export default reportAndBuleltinSerializer;
