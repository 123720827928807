export default `query VariableIncome($locales: [Locale!]!, $issuerId: String!) {
  pdContentParticipants(where: {issuerId: $issuerId}, locales: $locales) {
    issuerCompany
    logo{
      url
    }
    description
    pdSponsors{
      patrocinador
      telefonoDeContacto
      paginaWebPatrocinador
      correoDeContacto
      nemotecnico
      pdLinks{
        url
        text
      }
    }
    pdLinks{
      url
      text
    }
  }
  pdPages(where: {identifier: variable_income_market_global_issuer, isCanvas: false}, locales: $locales, stage: PUBLISHED) {
    content {
      ... on PdSection {
        identifier
        heading
        description
        tab {
          marketLevel
          identifier
          description
          summary
          seo {
            metaTitle
            metaDescription
            noIndex
            ogImage {
              fileName
            }
          }
        }
        button {
          ... on PdLink {
            text
            url
          }
        }
        pdLinks{
          ... on PdLink{
            text
          	url
          } 
        }
        contentBlock {
          ... on PdVariableIncomeContent{
            title
            description
            showSummaryGraphic
            pdIndices{
              fieldIdentifier
              description
            }
            captions {
              description
            }
          }
          ... on PdBanner {
            title
            description
            buttonText
            buttonUrl
            multimedia {
              fileName
              url
            }
            multimediaSmall {
              fileName
              url
            }
          }
        }
      }
    }
  }
}`;
