import getHome from './get-home';
import getMenu from './get-menu';
import getNews from './get-news';
import getPage from './get-page';
import getModal from './get-modal';
import getBadge from './get-badge';
import getSlugs from './get-slugs';
import getLearn from './get-learn';
import getLayout from './get-layout';
import getEvents from './get-events';
import getMarket from './get-market';
import getIndexN3 from './get-index-n3';
import getNewsAll from './get-news-all';
import getGlossary from './get-glossary';
import getNotFound from './get-not-found';
import getNoLanguage from './get-not-language';
import getNewsItem from './get-news-item';
import getFixedRent from './get-fixed-rent';
import getContactUs from './get-contact-us';
import getLoginModal from './get-login-modal';
import getDerivatives from './get-derivatives';
import getPageContent from './get-page-content';
import getNoTradingDay from './get-no-trading-day';
import getUpcomingEvents from './get-upcoming-events';
import getNewsCategories from './get-news-categories';
import getEventCategories from './get-event-categories';
import getCustomerService from './get-customer-service';
import getGeneralMessages from './get-general-messages';
import getLocalMarketTabs from './get-local-market-tabs';
import getRvLocalMarketEtf from './get-rv-local-market-etf';
import getLocalMarketOnline from './get-local-market-online';
import getGlossaryCategories from './get-glossary-categories';
import getListOfEtfsIssuers from './get-list-of-etfs-issuers';
import getIndexN3SubContent from './get-index-n3-sub-content';
import getNonTradingDaysPage from './get-non-trading-days-page';
import getRvMarketGlobalEtfs from './get-rv-market-global-etfs';
import getReportsAndBulletins from './get-reports-and-bulletins';
import getRvLocalMarketIssuer from './get-rv-local-market-issuer';
import getFinancialInformation from './get-financial-information';
import getRvMarketGlobalIssuer from './get-rv-market-global-issuer';
import getInstallersAndHandbooks from './get-installers-and-handbooks';
import getIssuersListLocalMarket from './get-issuers-list-local-market';
import getIssuersListGlobalMarket from './get-issuers-list-global-market';
import getReportsAndBulletinsCategories from './get-reports-and-bulletins-categories';
import getReportsAndBulletinsEmpties from './get-reports-and-bulletins-empties';
import getFinancialInformationCategories from './get-financial-information-categories';
import getOperationalEmergingRiskReports from './get-operational-emerging-risk-reports';
import getReportsAndBulletinsByCategoryAndDate from './get-reports-by-category-and-date';
import getOperationalEmergingRiskCategories from './get-operational-emerging-risk-category';
import getFinancialInformationByCategoryAndDate from './get-financial-information-by-category-and-date';
import getDisableModeN2 from './get-disable-mode-n2';
import getCanvasList from './canvas-components/get-canvas-list';
import getMarketLevelState from './get-market-level-state';
import getInfoTab from './get-info-tab';
import getHideGraphicLevelOne from './get-hide-graphic-level-one';
import getFavicon from './get-favicon';
import getTooltip from './get-tooltip';
import getHeaderLogin from './get-header-login';
import getModalProfile from './get-modal-profile';
import getFiltersMarketLvl2 from './get-filters-market-lvl2';

export default {
  getMenu,
  getHome,
  getNews,
  getPage,
  getModal,
  getBadge,
  getSlugs,
  getLearn,
  getLayout,
  getEvents,
  getMarket,
  getNewsAll,
  getIndexN3,
  getNotFound,
  getNoLanguage,
  getNewsItem,
  getGlossary,
  getFixedRent,
  getContactUs,
  getLoginModal,
  getPageContent,
  getDerivatives,
  getNoTradingDay,
  getUpcomingEvents,
  getNewsCategories,
  getCustomerService,
  getEventCategories,
  getLocalMarketTabs,
  getGeneralMessages,
  getRvLocalMarketEtf,
  getListOfEtfsIssuers,
  getLocalMarketOnline,
  getIndexN3SubContent,
  getGlossaryCategories,
  getRvMarketGlobalEtfs,
  getNonTradingDaysPage,
  getReportsAndBulletins,
  getRvLocalMarketIssuer,
  getRvMarketGlobalIssuer,
  getFinancialInformation,
  getInstallersAndHandbooks,
  getIssuersListLocalMarket,
  getIssuersListGlobalMarket,
  getReportsAndBulletinsCategories,
  getReportsAndBulletinsEmpties,
  getFinancialInformationCategories,
  getOperationalEmergingRiskReports,
  getOperationalEmergingRiskCategories,
  getReportsAndBulletinsByCategoryAndDate,
  getFinancialInformationByCategoryAndDate,
  getDisableModeN2,
  getCanvasList,
  getMarketLevelState,
  getInfoTab,
  getHideGraphicLevelOne,
  getFavicon,
  getTooltip,
  getHeaderLogin,
  getModalProfile,
  getFiltersMarketLvl2
};
