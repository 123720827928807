export default `query ContactUsQuery($locales: [Locale!]!) {
  pdPages(where: { identifier: contact_us }, stage: PUBLISHED, locales: $locales) {
    slug
    title
    content {
      ... on PdSection {
        identifier
        contentBlock {
           ... on PdBanner {
            id
            video
            title
            multimedia{
              url
            }
          }
          ... on PdContactForm {
            id
            descriptionForm: description
            requiredFieldsText
            email
            emailPlaceholder
            lastName
            lastNamePlaceholder
            name
            namePlaceholder
            phone
            phonePlaceholder
            message
            messagePlaceholder
            checkboxText1
            checkboxText2 {
              html
            }
            submitButtonText
            confirmationTitle
            confirmationMessage
            confirmationButtonText
          }
          ... on PdDataProcessingPolicy {
            id
            title
             description {
              html
            }
            buttonText
            version
          }
        }
      }
    }
  }
}
`;
