export default `query PdDisableModeN2SQuery($marketLevel: Int, $locales: [Locale!]!) {
    pdDisableModeN2S (where: {marketLevel: $marketLevel}, locales: $locales) {
    settingsIcon{url}
    disableMarketBtnUrl
    disableMarketBtnTitle
    disableMarketTitle
    disableMarketDescription
    marketIdentifier
    marketLevel
    active
  }
}`;