import validateField from '@utils/validations';
import { getEmbedYoutubeUrl } from '@utils/strings';

export const serializeContent = ({ identifier, contentBlock, ...data }) => {
  switch (identifier) {
    case 'banner': {
      return {
        banner: validateField(
          contentBlock?.map(
            ({
              multimedia,
              multimediaSmall,
              video,
              title,
              description,
              link
            }) => {
              const content = {
                title: validateField(title),
                description: validateField(description),
                link: validateField(link)
              };
              if (multimedia !== null) {
                content.image = validateField(multimedia);
                content.smallImage = validateField(multimediaSmall);
              }
              if (video !== null) {
                content.video = {
                  url: validateField(getEmbedYoutubeUrl(video))
                };
              }
              return content;
            }
          )
        )
      };
    }

    case 'calendar_and_events': {
      const { heading, button } = data;
      return {
        calendarContent: {
          title: validateField(heading),
          buttonMore: {
            text: validateField(button.text),
            redirectTo: validateField(button.url)
          }
        }
      };
    }

    case 'interesting_information': {
      return {
        interestingInformation: contentBlock.map((content) => ({
          id: validateField(content.id),
          author: validateField(content.author),
          title: validateField(content.title),
          category: validateField(content.category),
          subtitle: validateField(content.shortDescription),
          mainImage: validateField(content.mainImage),
          dateAndTime: validateField(content.dateAndTime),
          externalUrl: validateField(content.externalUrl)
        }))
      };
    }

    case 'local_market': {
      const { heading, description } = data;
      return {
        localMarket: {
          title: validateField(heading),
          subTitle: validateField(description)
        }
      };
    }

    default: {
      return { [identifier]: validateField(contentBlock) };
    }
  }
};

const homeSerializer = async (request) => {
  const { pdPages } = (await request) || {};
  const page = pdPages[0];
  const content = page?.content?.reduce(
    (finalContent, actualContent) => ({
      ...finalContent,
      ...serializeContent(actualContent)
    }),
    {}
  );
  return content;
};

export default homeSerializer;
