import seoSerializer from './seo-serializer';
import validateField from '@utils/validations';
import { bannersIdentifiers } from '@config/constants';

const bannerSerializer = (banners = []) =>
  banners.map((banner) => {
    const content = validateField(banner?.contentBlock[0]);
    return {
      title: validateField(content?.title),
      description: validateField(content?.description),
      button: {
        text: validateField(content?.buttonText),
        url: validateField(content?.buttonUrl)
      },
      image: {
        large: validateField(content?.multimedia?.url),
        small: validateField(content?.multimediaSmall?.url)
      }
    };
  });

const derivativesContentSerializer = (
  derivativesContents = [],
  tabs = undefined
) =>
  derivativesContents.map((derivativesContent, index) => {
    const content = derivativesContent?.contentBlock[0];
    if (
      index === 0 ||
      derivativesContents[index - 1]?.tab?.description !==
        derivativesContent?.tab?.description
    ) {
      tabs.push({
        identifier: validateField(derivativesContent?.tab?.identifier),
        title: validateField(derivativesContent?.tab?.description),
        summary: validateField(derivativesContent?.tab?.summary),
        seo: seoSerializer({ seo: derivativesContent?.tab?.seo })
      });
    }
    return {
      identifier: validateField(derivativesContent?.identifier),
      tab: validateField(derivativesContent?.tab),
      heading: validateField(derivativesContent?.heading),
      headingDescription: validateField(derivativesContent?.description),
      title: validateField(content?.label),
      description: validateField(content?.description),
      columns: validateField(content?.indexes),
      captions: validateField(content?.captions),
      button: validateField(content?.button),
      showSummaryGraphic: validateField(content?.showSummaryGraphic)
    };
  });

const derivativesSerializer = async (request) => {
  const { pdPages } = (await request) || {};
  const page = pdPages[0];
  const tabs = [];

  const banners = page?.content?.filter((item) =>
    [bannersIdentifiers.BANNER, bannersIdentifiers.SECONDARY_BANNER].includes(
      item.identifier
    )
  );
  const derivativesContents = page?.content?.filter(
    (item) =>
      ![
        bannersIdentifiers.BANNER,
        bannersIdentifiers.SECONDARY_BANNER
      ].includes(item.identifier)
  );

  return {
    tabs: validateField(tabs),
    banners: validateField(bannerSerializer(banners)),
    derivativesContents: validateField(
      derivativesContentSerializer(derivativesContents, tabs)
    )
  };
};

export default derivativesSerializer;
