/* eslint-disable radix */
import Axios from 'axios';
import { deleteCookieSession } from '@utils/cookies';
// GUARDA REFERENCIA AL TIMER ID PARA PODER DETENER LOS LISTENER
let timeoutID = null;
// GUARDA REFERENCIA A LOS LISTENER PARA DETENERLOS CUANDO SE ACTIVE EL LOGOUT
let resetFuction;
// VALIDAR SI EXISTE UNA SESIÓN
// eslint-disable-next-line import/prefer-default-export
export const validateSession = async (tokenSession, endpoint, domain) => {
  if (tokenSession) {
    const data = {
      tokenSession,
      domain
    };
    const respSession = await Axios.post(endpoint, data);
    if (respSession) {
      if (respSession.data.code === '200') {
        return respSession.data.user;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

// PERMITE OCULTAR EL MODAL PERFIL USER DANDO CLICK POR FUERA DEL COMPONENTE
export const mouseClicked = () => {
  window.addEventListener('click', function (e) {
    const elementClicked = document.querySelector('.modalPerfiluser');
    const modalPerfil = document.querySelector('.modalPerfil');
    const iconPerfil = document.getElementsByClassName('iconPerfil');
    if (!elementClicked?.contains(e.target) && modalPerfil !== null) {
      modalPerfil.style.display = 'none';
      iconPerfil[0].style.transform = 'rotate(0deg)';
    }
  });
};

// PERMITE DETENER LOS SNAPSHOTS DEL TIMER
export const stopTimer = () => {
  if (timeoutID) {
    window.clearTimeout(timeoutID);
  }
};

// PERMITE INICIALIZAR EL TIMER
export const startTimer = (
  setModalIsOpen,
  setModalInactive,
  modalInactive,
  dispatch,
  changeAuthActions,
  setUser
) => {
  stopTimer();
  timeoutID = window.setTimeout(
    () =>
      goInactive(
        setModalIsOpen,
        setModalInactive,
        modalInactive,
        dispatch,
        changeAuthActions,
        setUser
      ),
    parseInt(process.env.NEXT_PUBLIC_BVC_INACTIVITY)
  );
};

// PERMITE RESETEAR EL TIMER
export function resetTimer(
  setModalIsOpen,
  setModalInactive,
  modalInactive,
  dispatch,
  changeAuthActions,
  setUser
) {
  if (timeoutID) {
    window.clearTimeout(timeoutID);
    startTimer(
      setModalIsOpen,
      setModalInactive,
      modalInactive,
      dispatch,
      changeAuthActions,
      setUser
    );
  }
}

// PERMITE GUARDAR UNA REFERENCIA DEL RESET TIMER Y ENVIAR VARIABLES NECESARIAS
const callResetTimer = (
  setModalIsOpen,
  setModalInactive,
  modalInactive,
  dispatch,
  changeAuthActions,
  setUser
) => {
  resetTimer(
    setModalIsOpen,
    setModalInactive,
    modalInactive,
    dispatch,
    changeAuthActions,
    setUser
  );
};

// LISTENER QUE PERMITEN DETECTAR LA INACTIVIDAD DEL USUARIO
export function setupListenerInactivity(
  setModalIsOpen,
  setModalInactive,
  modalInactive,
  dispatch,
  changeAuthActions,
  setUser
) {
  resetFuction = () =>
    callResetTimer(
      setModalIsOpen,
      setModalInactive,
      modalInactive,
      dispatch,
      changeAuthActions,
      setUser
    );
  window.addEventListener('mousemove', resetFuction, false);
  window.addEventListener('mousedown', () => resetFuction, false);
  window.addEventListener('keypress', resetFuction, false);
  window.addEventListener('DOMMouseScroll', resetFuction, false);
  window.addEventListener('mousewheel', resetFuction, false);
  window.addEventListener('touchmove', resetFuction, false);
  window.addEventListener('MSPointerMove', resetFuction, false);
  mouseClicked();
  return startTimer(
    setModalIsOpen,
    setModalInactive,
    modalInactive,
    dispatch,
    changeAuthActions,
    setUser
  );
}

// FUNCIÓN QUE SE EJECUTA AL TERMINAR EL TIMER // LOGOUT
export function goInactive(
  setModalIsOpen,
  setModalInactive,
  modalInactive,
  dispatch,
  changeAuthActions,
  setUser
) {
  setModalIsOpen(true);
  setModalInactive({ ...modalInactive, isOpen: true });
  dispatch(changeAuthActions.changeAuth(null));
  if (timeoutID) {
    deleteCookieSession('_bvcSessionUser');
    setUser(null);
    window.clearTimeout(timeoutID);
  }
}

// FUNCION QUE PERMITE DESLOGUEAR AL USUARIO CUANDO VENCE LA SESIÓN
export function setupListenerLogout(expires, dispatch, changeAuthActions) {
  const now = new Date();
  const milisecondsLogout = expires - now.getTime();
  if (milisecondsLogout >= 0) {
    setTimeout(() => {
      deleteCookieSession('_bvcSessionUser');
      dispatch(changeAuthActions.changeAuth(null));
    }, milisecondsLogout);
  }
}

// PERMITE REMOVER LOS LISTENER CUANDO SE DESLOGUEA EL USUARIO
export const removeAllListener = () => {
  window.removeEventListener('mousemove', resetFuction, false);
  window.removeEventListener('mousedown', resetFuction, false);
  window.removeEventListener('keypress', resetFuction, false);
  window.removeEventListener('DOMMouseScroll', resetFuction, false);
  window.removeEventListener('mousewheel', resetFuction, false);
  window.removeEventListener('touchmove', resetFuction, false);
  window.removeEventListener('MSPointerMove', resetFuction, false);
};

// LOGOUT DEL USER
export const logout = async (token, setUser) => {
  const response = await Axios.delete(process.env.NEXT_PUBLIC_BVC_SING_OUT, {
    headers: {
      _bvcSessionUser: token
    }
  });
  if (response.data.code === '200') {
    deleteCookieSession('_bvcSessionUser');
    removeAllListener();
    setUser(null);
  }
};

// FUNCTION QUE PERMITE ABRIR EL MODAL DEL PERFIL DE USUARIO
export const onClickPerfil = () => {
  const modalPerfil = document.querySelector('.modalPerfil');
  const iconPerfil = document.getElementsByClassName('iconPerfil');
  if (modalPerfil.style.display === 'flex') {
    modalPerfil.style.display = 'none';
    iconPerfil[0].style.transform = 'rotate(0deg)';
  } else {
    modalPerfil.style.display = 'flex';
    iconPerfil[0].style.transform = 'rotate(-180deg)';
  }
};
