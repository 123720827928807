import seoSerializer from './seo-serializer';

const newsCategoriesSerializer = async (request) => {
  const { pdNewsCategories } = await request;
  return pdNewsCategories.map((content) => ({
    id: content.id,
    name: content.name,
    externalUrl: content.externalUrl ? content.externalUrl : '',
    welcomeText: content.welcomeText,
    welcomeButtonText: content.welcomeButtonText,
    seo: seoSerializer({ seo: content?.seo }),
    button: {
      text: content.button.text,
      url: content.button.url
    }
  }));
};

export default newsCategoriesSerializer;
