import validateField from '@utils/validations';

const subMenuFormatter = (subMenu) =>
  subMenu.map(({ title, slug, externalUrl, id, isExternalLink }) => ({
    name: validateField(title),
    url: validateField(slug),
    externalUrl: validateField(externalUrl),
    id: validateField(id),
    isExternalLink: validateField(isExternalLink)
  }));

const childrenFormatter = (children) => {
  const result = {};
  children.forEach((child) => {
    result[child.title] = child.childPage?.map(
      ({ slug, title, externalUrl, childPage, isExternalLink }) => {
        const formattedChild = {
          id: validateField(title),
          url: validateField(slug),
          externalUrl: validateField(externalUrl),
          name: validateField(title),
          isExternalLink: validateField(isExternalLink)
        };
        if (childPage && childPage.length) {
          formattedChild.subMenu = {
            name: title,
            items: subMenuFormatter(childPage)
          };
        }
        return formattedChild;
      }
    );
  });
  return result;
};

const menuSerializer = async (request) => {
  const { pdPages } = (await request) || {};
  const content = {};
  pdPages.forEach((page) => {
    content[page.title] = {
      copy: validateField(page.description?.html),
      children: childrenFormatter(page.childPage)
    };
  });
  return {
    menu: validateField(content)
  };
};
export default menuSerializer;
