export default `query MarketQuery($identifier: PdPageIdentifier!, $locales: [Locale!]!) {
    pdPages(where: {identifier: $identifier}, locales: $locales, stage: PUBLISHED) {
      identifier
      slug
      title
      content {
        ... on PdSection {
          identifier
          heading
          embedded
          contentBlock {
            ... on PdBanner {
              title
              description
              multimedia {
                fileName
                url
              }
              multimediaSmall {
                fileName
                url
              }
            }
          }
        }
      }
    }
  }
  `;
