import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  changeAuth: ['user'],
  loadingAuth: ['loading']
});

export default Creators;

export const INITIAL_STATE = {
  user: null,
  loading: true
};

export const changeAuth = (state, { user }) => ({
  ...state,
  user
});

export const loadingAuth = (state, { loading }) => ({
  ...state,
  loading
});

export const HANDLERS = {
  [Types.CHANGE_AUTH]: changeAuth,
  [Types.LOADING_AUTH]: loadingAuth
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
