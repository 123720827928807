const serializeLoginModal = ({ title, subtitle, body: { text }, links }) => ({
  title,
  subtitle,
  body: text,
  buttons: links
});

const loginModalSerializer = async (request) => {
  const { pdModals } = await request;
  return serializeLoginModal(pdModals[0]);
};

export default loginModalSerializer;
