const modalProfileSerializer = (payload) => {
  const { myPersonAccount, companyAccount, goToAccount, logOut } = payload;
  return {
    myPersonAccount,
    companyAccount,
    goToAccount,
    logOut
  };
};

export default modalProfileSerializer;
