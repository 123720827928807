import seoSerializer from './seo-serializer';
import validateField from '@utils/validations';
import { identifiersRV } from '@config/constants';
import { dashedCaseToCamel } from '@utils/strings';

const { localMarketIssuer } = identifiersRV;

const findTab = (marketLevel, tabs = []) =>
  tabs.findIndex(({ id }) => id === marketLevel);

const serializeContent = (content) => {
  const filteredData = content.reduce(
    (
      data,
      {
        identifier,
        heading,
        description,
        tab,
        contentBlock = [{}],
        pdLinks = [],
        button = {}
      }
    ) => {
      if (identifier === localMarketIssuer.banner_main) {
        const [{ multimedia, multimediaSmall, buttonText, buttonUrl }] =
          contentBlock;
        const {
          description: descriptionIssuer,
          issuerCompany,
          logo
        } = content.find(
          ({ identifier: id }) => id === localMarketIssuer.info_participant
        )?.contentBlock || {};

        data.banner_main = {
          title: validateField(issuerCompany),
          logo: validateField(logo?.url),
          description: validateField(descriptionIssuer),
          button: {
            text: validateField(buttonText),
            url: validateField(buttonUrl)
          },
          image: {
            large: validateField(multimedia?.url),
            small: validateField(multimediaSmall?.url)
          }
        };
      }
      if (
        identifier === localMarketIssuer.banner_bvc ||
        identifier === localMarketIssuer.banner_secondary
      ) {
        const [
          {
            multimediaSmall,
            description: descriptionBanner,
            multimedia,
            buttonText,
            buttonUrl,
            title
          }
        ] = contentBlock;
        data[dashedCaseToCamel(identifier)] = {
          title: validateField(title),
          description: validateField(descriptionBanner),
          image: {
            large: validateField(multimedia?.url),
            small: validateField(multimediaSmall?.url)
          },
          button: {
            text: validateField(buttonText),
            url: validateField(buttonUrl)
          }
        };
      }
      if (
        identifier === localMarketIssuer.issuer_information ||
        identifier === localMarketIssuer.financial_information ||
        identifier === localMarketIssuer.issuer_titles ||
        identifier === localMarketIssuer.information_operations ||
        identifier === localMarketIssuer.information_operations_contado ||
        identifier === localMarketIssuer.information_operations_repo ||
        identifier === localMarketIssuer.information_operations_ttv ||
        identifier === localMarketIssuer.info_summary ||
        identifier === localMarketIssuer.prices_and_volumes ||
        identifier === localMarketIssuer.fundamental ||
        identifier === localMarketIssuer.stock_information ||
        identifier === localMarketIssuer.historics
      ) {
        const { description: descriptionTab, marketLevel, seo } = tab;
        const indexTab = findTab(marketLevel, data.tabs);
        const logos =
          identifier === localMarketIssuer.issuer_information
            ? content.find(
                ({ identifier: id }) =>
                  id === localMarketIssuer.info_participant
              )?.contentBlock?.pdGeneralInformationLogos
            : pdLinks;
        const financialInformationLogos =
          identifier === localMarketIssuer.financial_information
            ? content.find(
                ({ identifier: id }) =>
                  id === localMarketIssuer.info_participant
              )?.contentBlock?.pdFinancialInformationLogos
            : pdLinks;
        const [sectionContent] = contentBlock;
        const section = {
          title: validateField(heading),
          description: validateField(description),
          showSummaryGraphic: validateField(sectionContent?.showSummaryGraphic),
          content: contentBlock,
          indices: validateField(sectionContent?.pdIndices),
          secondaryDescription: validateField(sectionContent?.description),
          captions:
            contentBlock[0]?.captions.map(({ description: label }) =>
              validateField(label)
            ) || [],
          logos,
          button,
          financialInformationLogos
        };
        indexTab >= 0
          ? (data.tabs[indexTab].sections[identifier] = section)
          : data.tabs.push({
              id: marketLevel,
              title: descriptionTab,
              sections: { [identifier]: section },
              seo: seoSerializer({ seo })
            });
      }
      return data;
    },
    { tabs: [] }
  );

  return filteredData;
};

const rvLocalMarketIssuerSerializer = async (request) => {
  const { pdPages, pdContentParticipants = [] } = await request;
  const [{ content }] = pdPages;
  const issuer = pdContentParticipants[0];
  content.push({
    contentBlock: issuer,
    identifier: localMarketIssuer.info_participant
  });
  return serializeContent(content);
};

export default rvLocalMarketIssuerSerializer;
