import validateField from '@utils/validations';

const notFoundSerializer = async (request) => {
  const { pdPages } = await request;
  return pdPages.map((page) => {
    const { identifier, slug, description, buttons = [] } = page || {};

    return {
      identifier: validateField(identifier),
      slug: validateField(slug),
      description: validateField(description?.html),
      buttons
    };
  })[0];
};

export default notFoundSerializer;
