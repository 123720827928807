import validateField from '@utils/validations';

export const serializeContent = ({ identifier, contentBlock }) => {
  if (!contentBlock.length) return {};
  switch (identifier) {
    case 'contact_us_banner': {
      const { title, multimedia } = contentBlock[0];
      return {
        banner: {
          title: validateField(title),
          image: validateField(multimedia?.url)
        }
      };
    }

    case 'contact_us_form': {
      const {
        requiredFieldsText,
        name,
        phone,
        email,
        message,
        lastName,
        checkboxText1,
        checkboxText2,
        descriptionForm,
        namePlaceholder,
        emailPlaceholder,
        phonePlaceholder,
        submitButtonText,
        confirmationTitle,
        messagePlaceholder,
        lastNamePlaceholder,
        confirmationMessage,
        confirmationButtonText
      } = contentBlock[0];
      return {
        form: {
          requiredFieldsText: validateField(requiredFieldsText),
          name: {
            label: validateField(name),
            placeholder: validateField(namePlaceholder)
          },
          email: {
            label: validateField(email),
            placeholder: validateField(emailPlaceholder)
          },
          lastName: {
            label: validateField(lastName),
            placeholder: validateField(lastNamePlaceholder)
          },
          phone: {
            label: validateField(phone),
            placeholder: validateField(phonePlaceholder)
          },
          message: {
            label: validateField(message),
            placeholder: validateField(messagePlaceholder)
          },
          description: validateField(descriptionForm),
          checkboxText1: validateField(checkboxText1),
          checkboxText2: validateField(checkboxText2?.html),
          button: { label: validateField(submitButtonText) },
          confirmation: {
            title: validateField(confirmationTitle),
            message: validateField(confirmationMessage),
            button: { label: validateField(confirmationButtonText) }
          }
        }
      };
    }
    case 'contact_us_terms': {
      const { title, description, buttonText, version } = contentBlock[0];
      return {
        terms: {
          title: validateField(title),
          description: validateField(
            description?.html.replace(/&#8232;/g, ' ')
          ),
          button: { label: validateField(buttonText) },
          version: { label: validateField(version) }
        }
      };
    }

    default: {
      return { [identifier]: validateField(contentBlock) };
    }
  }
};

const contactUsSerializer = async (request) => {
  const { pdPages } = (await request) || {};
  const page = pdPages[0];
  const content = page?.content?.reduce(
    (finalContent, actualContent) => ({
      ...finalContent,
      ...serializeContent(actualContent)
    }),
    {}
  );
  return content;
};

export default contactUsSerializer;
