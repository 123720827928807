const bannerSerializer = (banners) =>
  banners.map((banner) => {
    const content = banner.contentBlock[0];
    return {
      title: content.title,
      button: banner.button,
      identifier: banner.identifier,
      description: content.description,
      imageDesktop: content.multimedia?.url || null,
      imageMobile: content.multimediaSmall?.url || null
    };
  });

const modalSerializer = (modals) =>
  modals.reduce(
    (acc, { body, identifier, title, logo }) => ({
      ...acc,
      [identifier]: {
        body,
        title,
        logo
      }
    }),
    {}
  );

const localMarketOnlineSerializer = async (request) => {
  const { pdPages, pdModals } = await request;
  const page = pdPages[0];

  return {
    title: page.title,
    identifier: page.identifier,
    subTitle: page.description.text,
    banners: bannerSerializer(page.content),
    modals: modalSerializer(pdModals)
  };
};

export default localMarketOnlineSerializer;
