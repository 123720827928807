/* eslint no-param-reassign: 0 */
import axios from 'axios';

import RequestContext from './request-context';

const getURI = (uri, params) => {
  params = params || {};
  const matches = uri.match(/\{params.([a-zA-Z0-9_]+)}/g);
  if (matches) {
    matches.forEach((match) => {
      const name = match.replace('{params.', '').replace('}', '');
      uri = uri.replace(match, params[name]);
    });
  }
  return { uri, filters: params.filters };
};

const visitEndpoints = (endpoints, callback) => {
  Object.keys(endpoints).forEach((name) => {
    if (endpoints[name]) {
      const endpoint = endpoints[name];
      callback(name, endpoint.method, endpoint.uri);
    }
  });
};

const createAxios = (opts) => {
  const axiosInstance = axios.create(opts);

  const requestContext = new RequestContext(axiosInstance);
  requestContext.responseInterceptor();
  requestContext.requestInterceptor();

  return {
    axiosInstance
  };
};

class API {
  constructor(opts) {
    const endpoints = opts.endpoints || {};
    const { axiosInstance } = createAxios(opts);
    this.axios = axiosInstance;

    visitEndpoints(endpoints, (name, method, uri) => {
      this[name] = (params = {}) => {
        let { uri: URL, filters: k } = getURI(uri, params.url);
        if (['get', 'delete', 'head', 'options'].includes(method)) {
          if (typeof window !== 'undefined') {
            k = window.btoa(unescape(encodeURIComponent(k)));
          }
          if (typeof k == 'undefined') {
            k = "cGxhdGFmb3JtYWRpZ2l0YWw=";
          }
          return axiosInstance[method](URL, {
            headers: { ...params.headers, k }
          }).then((r) => r.data);
        }
        return axiosInstance[method](URL, params.body, {
          headers: { ...params.headers, k }
        }).then((r) => r.data);
      };
    });
  }
}

export default API;
