import { matchParagraphTag } from '@utils/strings';

const serializeContent = ({ identifier, contentBlock, ...content }) => {
  switch (identifier) {
    case 'services': {
      return {
        services: contentBlock.map(({ title, description, button, image }) => ({
          title,
          image: image.url,
          contentText: description,
          buttonTitle: button.text,
          href: button.url
        }))
      };
    }
    case 'where_we_are': {
      return {
        headQuartersHeading: content.heading,
        headQuarters: contentBlock.map(
          ({ name: city, address: addressData, phone, image }) => {
            const [name, address, clarificationA, clarificationB] =
              addressData.split('\n');
            const res = {
              name,
              address,
              city,
              contact: [phone],
              img: image.url
            };
            if (clarificationA) {
              res.clarificationA = clarificationA;
            }
            if (clarificationB) {
              res.clarificationB = clarificationB;
            }
            return res;
          }
        )
      };
    }
    default:
      break;
  }
};

const customerServiceSerializer = async (request) => {
  const { pdPages } = await request;
  const page = pdPages[0];
  const [subtitleHtml, ...contactsHtml] = matchParagraphTag(
    page?.description?.html
  );
  return {
    subtitleHtml,
    contactsHtml,
    title: page.title,
    ...page.content.reduce(
      (finalContent, actualContent) => ({
        ...finalContent,
        ...serializeContent(actualContent)
      }),
      {}
    )
  };
};

export default customerServiceSerializer;
