/* eslint prefer-promise-reject-errors: 0 */
/* eslint-disable no-console */
import generateToken from '@utils/tokenGenerator';

class RequestContext {
  constructor(axios) {
    this.axios = axios;
  }

  responseInterceptor() {
    this.axios.interceptors.response.use(
      (response) => {
        if (response.data) {
          return response.data;
        }
        return response;
      },
      (error) => {
        console.error(error);
        return Promise.resolve({
          error
        });
      }
    );
  }

  requestInterceptor() {
    this.axios.interceptors.request.use(
      async (config) => {
        // Generate unique jwt token
        config.headers.token = await generateToken();

        return config;
      },
      (error) => Promise.reject(error)
    );
  }
}

export default RequestContext;
