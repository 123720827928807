import { initGA, logPageView } from '@config/lib/analytics';

class AnalyticsLoader {
  static boot(App) {
    const { analytics, isProduction } = App.config;

    if (!window.GA_INITIALIZED && isProduction) {
      initGA(analytics.trackingId);
      window.GA_INITIALIZED = true;
    }

    logPageView();
  }
}

export default AnalyticsLoader;
