import { useDispatch, useSelector } from 'react-redux';
import objUrls from '../utils/routesM2translate';
import useRouter from '@hooks/useRouter';
import { themes } from '@config/constants';
import configActions from '@redux/reducers/config';

const useConfig = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { fontSize, theme, language } = useSelector(({ config }) => config);

  const setFontSize = (newFontSize) => {
    dispatch(configActions.changeFontSize(newFontSize));
  };

  const toggleLanguage = () => {
    if (
      router.pathname === '/local-market-online' ||
      router.pathname === '/mercado-local-en-linea'
    ) {
      const splitUrl = window.location.href.split('=');

      const getTranslatedUrl = objUrls[language][splitUrl[1]];
      router.query.tab = getTranslatedUrl;
      router.push(router);
    }
    dispatch(configActions.toggleLanguage());
  };

  const toggleTheme = () => {
    dispatch(configActions.toggleTheme());
  };

  return [
    { fontSize, theme, language },
    { setFontSize, toggleLanguage, toggleTheme }
  ];
};

export const useLight = () => {
  const [{ theme }] = useConfig();
  const isLight = themes.LIGHT === theme;
  return isLight;
};

export const useDark = () => {
  const [{ theme }] = useConfig();
  const isDark = themes.DARK === theme;
  return isDark;
};

export default useConfig;
