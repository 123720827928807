const seoSerializer = (unserializedSeo = {}) => {
  const result = {};
  const { title, seo, slug } = unserializedSeo || {};
  if (title) result.title = title;
  if (seo) {
    result.meta = {};
    if (seo.metaTitle) {
      result.meta.metaTitle = seo.metaTitle;
    }
    if (seo.metaDescription) {
      result.meta.metaDescription = seo.metaDescription;
    }
    if (seo.noIndex) {
      result.meta.noIndex = seo.noIndex;
    }
    if (seo.ogImage) {
      result.meta.ogImage = seo.ogImage;
    }
  }
  if (slug) result.slug = slug;

  return result;
};

export default seoSerializer;
