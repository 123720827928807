export default `query LocalMarketOnlineQuery($locales: [Locale!]!) {
  pdPages(where: { identifier: local_market_online }, locales: $locales) {
    identifier
    slug
    title
    description {
        text
    }
    content {
      ... on PdSection {
        identifier
        heading
        embedded
        contentBlock {
          ... on PdBanner {
            title
            description
            multimedia {
              fileName
              url
            }
            multimediaSmall {
              fileName
              url
            }
          }
        }
        button {
            text
            url
        }
      }
    }
  }
  pdModals (where: {marketLevel: "2"}, locales: $locales) {
    identifier
    title
    logo {
      ... on PdLink {
        icon {
          fileName
          url
        }
      }
    }
    body {
        html
    }
  }
}
`;
