export default {
  RichText: `... on PdRichText {
    componentType: __typename
    titleRichText: title
    content {
      ... on PdRichParagraph {
        componentType: __typename
        content {
          html
        }
      }
      ... on PdRichList {
        componentType: __typename
        content {
          html
        }
      }
    }
    link {
       text
       url
       isButton
    }
  }
`,
  MainBannerCanvas: `... on PdBanner {
  componentType: __typename
  multimedia {
    fileName
    url
  }
  multimediaSmall {
    fileName
    url
  }
  logoBanner: logo {
    fileName
    url
  }
  video
  titleBanner: title
  description
  link {
    url
  }
  buttonText
  buttonUrl
  filter
  }`,
  TabsDownloads: `... on PdFile {
  pdFileId: id
  componentType: __typename
  hasDateFilter
  hasCategoryFilter
  orderFilter
  hasSearchFilter
  placeholderSearchField
  pdTabDownloadCustoms {
    pdTabsNames {
      tabName
      id
    }
  }
}`,
  CardLogo: `... on PdCardLogo {
  componentType: __typename
  logo {
    fileName
    url
  }
  cardLogoDescription: description
  urlCallToAction
  textCallToAction
  file {
    fileName
    url
  }
  pdPopup {
    title
    duration
    description
  }
}`,
  Collapsable: `... on PdCollapsable {
      componentType: __typename
      id
      cardDescription
      containCard
      number
      iconCard {
        url
      }
      category {
        name
        hasSearchFilter
        placeholderSearchField
      }
      link {
        url
        text
        isButton
        file {
          url
          fileName
        }
        pdPopup {
          title
          duration
          description
        }
      }
    }
  `,
  ContactProfiles: `... on PdContactProfile {
    componentType: __typename
    contactProfileName: name
    nameUrl
    occupation
    occupationUrl
    phone
    phoneUrl
    email
    images {
      icon {
        fileName
        url
      }
      url
    }
    hoverImages {
      icon {
        fileName
        url
      }
      url
    }
  }
  `,
  IconContact: `... on PdIconAndContact {
      componentType: __typename
      imageContact: image {
          url
      }
      imageUrl
      subTitle1
      subTitle1Url
      subTitle2
      subTitle2Url
      titleContact: title
      titleUrl
    }
  `,
  IconRichText: `... on PdIconRichText {
      componentType: __typename
      iconIconRichText: icon {
        url
      }
      textUrlButtonIconRichText: urlButtonOrText
      textButtonIconRichText: textButton
      titleIconRichText: title
      textIconRichText: text {
        html
      }
    }
  `,
  Links: `... on PdDownloadable {
    componentType: __typename
    titleLinks: titleComponent
    pdDownloadableCards {
      date
      description
      authorship
      titleNewOrFile
      urlTitle
      file {
        url
        fileName
      }
      pdPopup{
        title
        description
        duration
      }
    }
  }`,
  LogoLink: `... on PdLogoLink {
    componentType: __typename
    titleLogoLink: title
    urlLogoLink: url
    iconLogoLink: icon {
        url
    }
  }`,
  MacroCard: `... on PdMacroCard {
      componentType: __typename
      macroCardTitle: title
      macroCardDescription: description
      macroCardBlueFilter: blueFilter
      macroCardImage: image {
        url
      }
      macroCardLink: pdLink {
        text
        url
        file {
          url
          fileName
        }
        pdPopup {
          title
          duration
          description
        }
      }
    }
  `,
  MacroTable: `... on PdMacroTable {
    table: content {
      html
    }
  }
  `,
  ParagraphBanner: `... on PdBannerParagraph {
    componentType: __typename
    bannerParagraphTextCallToAction: textCallToAction
    urlCallToAction
    file {
      url
      fileName
    }
    titleImage
    bannerParagraphImage: image {
      url
      fileName
    }
    imageSmall {
      fileName
      url
    }
    pdPopup {
      title
      duration
      description
    }
    urlVideo
    bannerParagraphTitle: title
    shortImageDescription
    description
    filter
    bannerAlignment
    secondaryButtonText
    secondaryButtonUrl
    secondaryButtonFile {
      url
      fileName
    }
  }`,
  Photography: `... on PdPhotography {
      componentType: __typename
      photographyImage: image {
        fileName
        url
        id
      }
      alignLeft
      alignRight
    }
  `,
  RichTextCard: `... on PdRichTextCard {
      componentType: __typename
      richTextCardId: id
      richTextCardTitle: title
      richTextCardContent: text {
        html
      }
      richTextCardButtonText: buttonText
      richTextCardButtonUrl: urlTextOrButton
      richTextCardButtonIsButton: isButton
    }
  `,
  RichTextMultimedia: `... on PdRichTextMultimedia {
    componentType: __typename
    richTextMultimediaImage: image {
        url
    }
    text {
        html
    }
    richTextMultimediaTitle:title
    video
    positionImage
    button
    buttonUrl
    imageButtonText
    imageButtonUrl
    pdLinks {
        icon {
          fileName
          url
        }
        url
        text
      }
      pdButtons {
        id
        url
        text
      }      
  }`,
  SimpleDownload: `... on PdSimpleDownload {
    componentType: __typename
    titleSimpleDownload:title
    descriptionSimpleDownload:description
    id
    pdDownloadCards {
      fileDate
      fileDescription
      fileTitle
      id
      file {
        url,
        fileName
      }
      pdPopup {
        title
        duration
        description
      }
    }
  }`,
  StepByStep: `... on PdStepByStep {
    componentType: __typename
    titleStep: title
      subTitleStep: subtitle
      descriptionStep: description
      pdLinks {
        text
        url
        isButton
        labelColor
        backgroundColor
        file {
          url
          fileName
        }
        pdPopup {
          title
          duration
          description
        }
      }
  }
  `,
  HeadQuarters: `... on PdHeadquarter {
      componentType: __typename
      city
      cityUrl
      headquarter
      headquarterUrl
      address
      addressUrl
      specifications
      specificationsUrl
      building
      buildingUrl
      pbx
      pbxUrl
      headquarterImage: image {
        url
      }
      imageUrl
      blueFilters
    }
  `
};
