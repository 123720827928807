import { cmsLocales } from '@config/constants';

const getGlossary = ({ locale }) => {
  const categoryFilter =
    locale === cmsLocales.es
      ? 'pdGlossaryCategorySpanish'
      : 'pdGlossaryCategoryEnglish';
  return `
query getGlossary($locales: [Locale!]!, $idCanvas: ID!, $category: String!) {
  pdGlossaries(where: {pdCanvasComponents_every: {id: $idCanvas},
  ${categoryFilter} : {name: $category}}, locales: $locales, stage: PUBLISHED) {
    componentType: __typename
    titleCard
    containCard {
      html
    }
    cardIcon {
      url
    }
    pdLinks {
      url
      text
      isButton
      file {
        url
        fileName
      }
      pdPopup {
        title
        duration
        description
      }
    }
    pdGlossaryCategory : ${categoryFilter} {
      name
    }
  }
}
`;
};

export default getGlossary;
