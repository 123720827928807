import cookies from 'next-cookies';

import { cmsLocales } from '@config/constants';

export const getLocaleCmsCookie = (context) => {
  const cookieLanguage = cookies(context).language;
  const locale = cmsLocales[cookieLanguage];
  return locale;
};

export const getLocalToken = (context) => {
  // eslint-disable-next-line no-underscore-dangle
  return cookies(context)._bvcSessionUser;
};

export const getTooltip = (context) => {
  return cookies(context).tooltip;
};

export const getCookies = (context) => cookies(context);

export const setCookie = (key, value) => {
  document.cookie = `${key}=${value}; path=/;`;
};

export const deleteCookie = (key) => {
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

export const deleteCookieSession = (key) => {
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=bvc.com.co`;
};
