/* eslint-disable func-names, prefer-rest-params, no-underscore-dangle */
/**
 * We are using Hotjar like behavior tool tracker
 * for more info https://www.hotjar.com/
 */
import { hotjar } from 'react-hotjar';

class BehaviorTrackerLoader {
  static boot(app) {
    const { siteId, version = 6 } = app.config.behaviorTracker;
    if (app.config.isProduction) {
      hotjar.initialize(siteId, version);
    }
  }
}

export default BehaviorTrackerLoader;
