export default `query FixedRent($locales: [Locale!]!, $identifier: PdPageIdentifier) {
  pdPages(where: {identifier: $identifier, isCanvas: false}, locales: $locales, stage: PUBLISHED) {
  content {
      ... on PdSection {
        identifier
        heading
        description
        tab {
          marketLevel
          identifier
          description
          summary
          pdPopup {
            title
            duration
            description
          }
          seo {
            metaTitle
            metaDescription
            noIndex
            ogImage {
              fileName
            }
          }
        }
        contentBlock {
          ... on PdFixedRentContent {
            subTitle: title
            secondaryDescription: description
            showSummaryGraphic
            pdIndices {
              fieldIdentifier
              description
            }
            pdCaptions {
              description
            }
            buttonText
          }
          ... on PdBanner {
            title
            description
            buttonText
            buttonUrl
            multimedia {
              fileName
              url
            }
            multimediaSmall {
              fileName
              url
            }
          }
        }
      }
    }
  }
}`;
