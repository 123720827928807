export default `
query Indexes($locales: [Locale!]!) {
  pdPages(where: {identifier: indexes, isCanvas: false}, locales: $locales, stage: PUBLISHED) {
    title
    identifier
      content {
      ... on PdSection {
        heading
        description
        identifier
        contentBlock {
          ... on PdIndexContent {
            label
            description
            pdIndices {
              description
              fieldIdentifier
            }
            pdLinks {
              text
            }
          }
        }
      }
    }
  }
}
`;
