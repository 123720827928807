export default `
query getNonTradingDaysPage($locales: [Locale!]!) {
  pdPages(where: {identifier: listOfETFsIssuers }, stage: PUBLISHED, locales: $locales) {
    content {
      ... on PdSection {
        heading
        identifier
        contentBlock {
          ... on PdBanner {
            titleBanner: title
            descriptionBanner: description
            multimedia {
              url
            }
            multimediaSmall {
              url
            }
            buttonText
            buttonUrl
          }
          ... on PdEtFsIssuer{
            description
            disclaimer
            pdTabs {
              description
              summary
              seo {
                metaTitle
                metaDescription
                noIndex
                ogImage {
                  fileName
                }
              }
            }
            pdIndices {
              ... on PdIndex {
                id
                description
                fieldValue
              }
            }
          }
        }
      }
    }
  }
}
`;
