import seoSerializer from './seo-serializer';
import validateField from '@utils/validations';

const financialInformationCategoriesSerializer = async (request) => {
  const { pdFinancialInformationCategories } = await request;
  return pdFinancialInformationCategories.map(
    ({ seo, subCategories, ...financialInformationCategory }) => ({
      ...financialInformationCategory,
      seo: seoSerializer({ seo }),
      subCategories: validateField(
        subCategories?.map((subCategory) => ({
          ...subCategory,
          seo: seoSerializer({ seo: subCategory?.seo })
        }))
      )
    })
  );
};

export default financialInformationCategoriesSerializer;
