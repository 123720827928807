import pageSerializer from './page-serializer';
import homeSerializer from './home-serializer';
import menuSerializer from './menu-serializer';
import newsSerializer from './news-serializer';
import modalSerializer from './modal-serializer';
import badgeSerializer from './badge-serializer';
import learnSerializer from './learn-serializer';
import slugsSerializer from './slugs-serializer';
import eventsSerializer from './events-serializer';
import marketSerializer from './market-serializer';
import layoutSerializer from './layout-serializer';
import indexN3Serializer from './index-n3-serializer';
import glossarySerializer from './glossary-serializer';
import notFoundSerializer from './not-found-serializer';
import fixedRentSerializer from './fixed-rent-serializer';
import contactUsSerializer from './contact-us-serializer';
import loginModalSerializer from './login-modal-serializer';
import derivativesSerializer from './derivatives-serializer';
import pageContentSerializer from './page-content-serializer';
import noTradingDaySerializer from './no-trading-day-serializer';
import newsCategoriesSerializer from './news-categories-serializer';
import upcomingEventsSerializer from './upcoming-events-serializer';
import eventCategoriesSerializer from './event-categories-serializer';
import generalMessagesSerializer from './general-messages-serializer';
import costumerServiceSerializer from './customer-service-serializer';
import localMarketTabsSerializer from './local-market-tabs-serializer';
import rvLocalMarketEtfSerializer from './rv-local-market-etf-serializer';
import localMarketOnlineSerializer from './local-market-online-serializer';
import listOfETFsIssuersSerializer from './list-of-etfs-issuers-serializer';
import indexN3SubContentSerializer from './index-n3-sub-content-serializer';
import glossaryCategoriesSerializer from './glossary-categories-serializer';
import reportAndBuleltinSerializer from './report-and-buleltin-serializer';
import nonTradingDaysPageSerializer from './non-trading-days-page-serializer';
import financialInformationSerializer from './financial-information-serializer';
import rvMarketGlobalEtfsSerializer from './rv-market-global-etfs-serializer.js';
import rvLocalMarketIssuerSerializer from './rv-local-market-issuer-serializer.js';
import rvMarketGlobalIssuerSerializer from './rv-market-global-issuer-serializer.js';
import installersAndHandbooksSerializer from './installers-and-handbooks-serializer';
import issuersListLocalMarketSerializer from './issuers-list-local-market-serializer';
import issuersListGlobalMarketSerializer from './issuers-list-global-market-serializer';
import reportByCategoryAndDateSerializer from './report-by-category-and-date-serializer';
import reportAndBulletinCategoriesSerializer from './report-and-bulletin-categories-serializer';
import financialInformationCategoriesSerializer from './financial-information-categories-serializer';
import operationalEmergingRiskReportsSerializer from './operational-emerging-risk-reports-serializer';
import operationalEmergingRiskCategoriesSerializer from './operational-emerging-risk-category-serializer';
import financialInformationByCategoryAndDateSerializer from './financial-information-by-category-and-date-serializer';
import infoTabSerializer from './info-tab-serializer';
import hideGraphicLevelOneSerializer from './hide-graphic-level-one-serializer';
import faviconSerializer from './favicon-serializer';
import tooltipSerializer from './tooltip-serializer';
import headerLoginSerializer from './header-login-serializer';
import filtersMarketLvl2Serializer from './filters-market-lvl2-serializer';

export default {
  getPage: pageSerializer,
  getHome: homeSerializer,
  getMenu: menuSerializer,
  getNews: newsSerializer,
  getSlugs: slugsSerializer,
  getLearn: learnSerializer,
  getBadge: badgeSerializer,
  getModal: modalSerializer,
  getLayout: layoutSerializer,
  getEvents: eventsSerializer,
  getMarket: marketSerializer,
  getIndexN3: indexN3Serializer,
  getGlossary: glossarySerializer,
  getNotFound: notFoundSerializer,
  getContactUs: contactUsSerializer,
  getFixedRent: fixedRentSerializer,
  getLoginModal: loginModalSerializer,
  getPageContent: pageContentSerializer,
  getDerivatives: derivativesSerializer,
  getNoTradingDay: noTradingDaySerializer,
  getUpcomingEvents: upcomingEventsSerializer,
  getNewsCategories: newsCategoriesSerializer,
  getGeneralMessages: generalMessagesSerializer,
  getEventCategories: eventCategoriesSerializer,
  getCustomerService: costumerServiceSerializer,
  getLocalMarketTabs: localMarketTabsSerializer,
  getRvLocalMarketEtf: rvLocalMarketEtfSerializer,
  getIndexN3SubContent: indexN3SubContentSerializer,
  getLocalMarketOnline: localMarketOnlineSerializer,
  getListOfEtfsIssuers: listOfETFsIssuersSerializer,
  getGlossaryCategories: glossaryCategoriesSerializer,
  getNonTradingDaysPage: nonTradingDaysPageSerializer,
  getReportsAndBulletins: reportAndBuleltinSerializer,
  getRvMarketGlobalEtfs: rvMarketGlobalEtfsSerializer,
  getRvLocalMarketIssuer: rvLocalMarketIssuerSerializer,
  getRvMarketGlobalIssuer: rvMarketGlobalIssuerSerializer,
  getFinancialInformation: financialInformationSerializer,
  getInstallersAndHandbooks: installersAndHandbooksSerializer,
  getIssuersListLocalMarket: issuersListLocalMarketSerializer,
  getIssuersListGlobalMarket: issuersListGlobalMarketSerializer,
  getReportsAndBulletinsCategories: reportAndBulletinCategoriesSerializer,
  getReportsAndBulletinsByCategoryAndDate: reportByCategoryAndDateSerializer,
  getOperationalEmergingRiskReports: operationalEmergingRiskReportsSerializer,
  getFinancialInformationCategories: financialInformationCategoriesSerializer,
  getOperationalEmergingRiskCategories:
    operationalEmergingRiskCategoriesSerializer,
  getFinancialInformationByCategoryAndDate:
    financialInformationByCategoryAndDateSerializer,
  getInfoTab: infoTabSerializer,
  getHideGraphicLevelOne: hideGraphicLevelOneSerializer,
  getFavicon: faviconSerializer,
  getTooltip: tooltipSerializer,
  getHeaderLogin: headerLoginSerializer,
  getFiltersMarketLvl2: filtersMarketLvl2Serializer
};
