export default `query hideGraphicLevelOne($locales: [Locale!]!) {
  pdHideGraphicLevel1S(locales: $locales) {
    descriptionText
    mainImage {
      url
      altText
    }
    marketIdentifier
    active
  }
}`;
  