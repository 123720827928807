export default `
  query MyQuery($locales: [Locale!]!) {
    pdNewsCategories(locales: $locales, stage: PUBLISHED) {
      id
      name
      externalUrl
      welcomeText
      button {
        text
        url
      }
      welcomeButtonText
      seo {
        metaTitle
        metaDescription
        noIndex
        ogImage {
          fileName
        }
      }
    }
  }
`;
