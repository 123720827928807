export default `
query GetCards($pdFileId: ID!, $locales: [Locale!]!, $tabName: String, $skip: Int){ 
  pdFiles(where: {id: $pdFileId}, locales: $locales){ 
    pdFilesCard(where: {titleTab: $tabName}, orderBy: fileDate_DESC, first: 11, skip: $skip){ 
      cardSeo: seo {
      metaTitle
      metaDescription
      noIndex
      ogImage {
        fileName
      }
    }
    descriptionCard: description
    cardFile: file {
      url
      fileName
    }
    cardPopup: pdPopup {
      title
      duration
      description
    }
    id
    titleCard
    titleTab
    fileDate
    pdFilesCategory {
      name
      id
    } 
    } 
  } 
} 
`;
