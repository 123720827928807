/* istanbul ignore file */
/* eslint-disable no-console */
import config from './config';
import ApiLoader from './boot-loaders/ApiLoader';
import CmsLoader from './boot-loaders/CmsLoader';
import AnalyticsLoader from './boot-loaders/AnalyticsLoader';
import BehaviorTrackerLoader from './boot-loaders/BehaviorTrackerLoader';

const loadersServer = [ApiLoader, CmsLoader];

const loadersClient = [
  ...loadersServer,
  AnalyticsLoader,
  BehaviorTrackerLoader
];

class App {
  static boot() {
    this.config = config;
    const isClient = typeof window !== 'undefined';
    const loaders = isClient ? loadersClient : loadersServer;
    try {
      loaders.forEach((loader) => {
        loader.boot(App);
      });
      this.load = true;
      console.info('loaders done');
    } catch (error) {
      console.error('Error while booting the app', error);
    }
  }

  static getInstance() {
    if (this.load) return this;
    this.boot();
    return this;
  }
}

export default App;
