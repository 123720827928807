import validateField from '@utils/validations';
import seoSerializer from './seo-serializer';

const infoTabSerializer = async (request) => {
  const { pdFiles } = await request;
  const { pdFilesCard } = pdFiles[0];

  return pdFilesCard.map((fileCard) => {
    const {
      id,
      cardFile,
      titleCard,
      titleTab,
      cardSeo,
      fileDate,
      pdFilesCategory,
      descriptionCard,
      cardPopup
    } = fileCard;
    return {
      id: validateField(id),
      titleTab: validateField(titleTab),
      title: validateField(titleCard),
      description: validateField(descriptionCard),
      date: validateField(fileDate),
      cardFile: validateField(cardFile),
      category: validateField(pdFilesCategory?.name),
      modalDownload: validateField(cardPopup),
      cardSeo: seoSerializer({ seo: cardSeo })
    };
  });
};

export default infoTabSerializer;
