export default `
query IndexesSubContent($locales: [Locale!]!, $index: PdIndexesIdentifier) {
  pdIndexSubContent(where: { index: $index },locales: $locales) {
    index
    subMarket
    showHistorySection
    showGraphics
    baskets {
      id
      basketTitle: title
      description
      attached {
        fileName
        url
      }
    }
    topBanner {
      title
      description
      multimedia {
        fileName
        url
      }
      multimediaSmall {
        fileName
        url
      }
      buttonText
      buttonUrl
    }
    bottomBanner {
      title
      description
      multimedia {
        fileName
        url
      }
      multimediaSmall {
        fileName
        url
      }
      buttonText
      buttonUrl
    }
  }
}
`;
