export default `
  query PDEmptyReport($locales: [Locale!]!) {
    pdReportAndBulletingEmpties (locales: $locales) {
      logo {
        url
      }
      title
      description
    }
  }
`