export default `
  query OperationalEmerginRiskReports($locales: [Locale!]!) {
    pdOperationalEmergingRiskReports(locales: $locales, stage: PUBLISHED, orderBy: date_DESC) {
      id
      title
      description
      date
      attached{
        id
        fileName
        url
      }
    }
  }
`;
