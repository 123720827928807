import validateField from '@utils/validations';

const graphicSerializer = ({ contentBlock }) => {
  const { label, description } = contentBlock[0] || {};
  const { label: legendTitle, description: legendDescription } =
    contentBlock[1] || {};
  return {
    indexes: {
      graphic: {
        title: validateField(label),
        description: validateField(description),
        legendTitle: validateField(legendTitle),
        legendDescription: validateField(legendDescription)
      }
    }
  };
};

const summarySerializer = ({ contentBlock }) => {
  const { label, description, pdIndices } = contentBlock[0] || {};
  return {
    indexes: {
      summary: {
        title: validateField(label),
        description: validateField(description),
        columns: validateField(
          pdIndices?.map(({ fieldIdentifier, description: title }) => ({
            field: validateField(fieldIdentifier),
            title: validateField(title)
          }))
        )
      }
    }
  };
};

const historicSerializer = ({ contentBlock }) => {
  const { label, description, pdLinks } = contentBlock[0] || {};
  return {
    indexes: {
      historic: {
        title: validateField(label),
        description: validateField(description),
        button: {
          text: validateField(pdLinks[0]?.text)
        }
      }
    }
  };
};

const serializers = {
  index_content_graphic: graphicSerializer,
  content_overview: summarySerializer,
  history_content: historicSerializer
};

const indexN3Serializer = async (request) => {
  const { pdPages } = (await request) || [];
  const pdPage = pdPages[0] || {};

  return {
    subMarket: validateField(pdPage?.identifier),
    ...validateField(
      pdPage?.content?.reduce((acc, { identifier, ...rest }) => {
        const { indexes, ...accRest } = acc;
        const { indexes: serializeIndexes, ...serializeRest } =
          serializers[identifier](rest) || {};
        acc = {
          ...accRest,
          ...serializeRest,
          indexes: { ...indexes, ...serializeIndexes }
        };
        return acc;
      }, {})
    )
  };
};

export default indexN3Serializer;
