export default `
  query OperationalEmerginRiskCategories($locales: [Locale!]!) {
    pdOperationalEmergingRiskCategories(locales: $locales, stage: PUBLISHED, orderBy: sort_ASC) {
      id
      name
      pdOperationalEmergingRisks(orderBy: date_DESC) {
        id
        title
        description
        date
        attached {
          id
          url
          fileName
        }
      }
      description {
        html
      }
      bulletTitle1
      bulletTitle2
      bulletDescription1
      bulletDescription2
      seo {
        metaTitle
        metaDescription
        noIndex
        ogImage {
          fileName
        }
      }
    }
  }
`;
