import validateField from '@utils/validations';

const hideGraphicLevelOneSerializer = async(request) => {
  const { pdHideGraphicLevel1S } = await request;

  return pdHideGraphicLevel1S.map((bannerInfo) => {
    const {
      descriptionText, 
      mainImage,
      marketIdentifier,
      active
    } = bannerInfo; 

    return {
      description: validateField(descriptionText),
      imageUrl: validateField(mainImage?.url),
      alt: validateField(mainImage?.altText),
      marketIdentifier: validateField(marketIdentifier),
      active: validateField(active)
    }
  });
}

export default hideGraphicLevelOneSerializer;