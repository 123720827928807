const marketSerializer = async (request) => {
  const result = await request;
  if (result.pdPages[0]?.content) {
    const unstructuredRequest = result.pdPages[0].content;
    const staticBannerInformation = unstructuredRequest[0].contentBlock[0];
    const embeddedInformation = unstructuredRequest[1];
    const {
      multimedia: image,
      multimediaSmall: imageSmall,
      title,
      description
    } = staticBannerInformation;
    const { embedded: embedLink } = embeddedInformation;
    const staticBanner =
      image && imageSmall && title && description
        ? {
            image,
            imageSmall,
            title,
            description
          }
        : undefined;

    return {
      staticBanner,
      embedLink
    };
  }
  return null;
};

export default marketSerializer;
