import moment from 'moment';

const upcomingEventsSerializer = async (request) => {
  const { pdEvents } = await request;
  const upcomingEvents = pdEvents
    .reduce((finalEvents, { dateAndTime }) => {
      const date = moment(dateAndTime, moment.ISO_8601).format('YYYY-MM-DD');
      if (!finalEvents.find((event) => event === date)) {
        finalEvents.push(date);
      }
      return finalEvents;
    }, [])
    .map((event) => ({ date: moment(event, 'YYYY-MM-DD').format() }));

  return upcomingEvents;
};

export default upcomingEventsSerializer;
