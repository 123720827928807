import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

export const defaultSeoValues = {
  title: 'Bolsa de Valores de Colombia',
  keywords:
    'BVC,bursátil,bonos,bolsa millonaria,bolsa de valores de colombia,bolsa,atención a inversionistas,atención a accionistas,asesoría,accionistas,acciones',
  description:
    'Línea única de atención al cliente en Bogotá (571) 3139000. PBX Bogotá: bvc (571) 3139000 | deceval (571) 4193737. bvc © 2020.',
  noIndex: false,
  author: 'bvc'
};

const Seo = ({ title, meta, snippets, slug, favicon }) => {
  const router = useRouter();

  const {
    metaDescription: pageDescription = defaultSeoValues.description,
    focusKeywords: pageKeywords = defaultSeoValues.keywords,
    noIndex = defaultSeoValues.noIndex,
    metaTitle: pageTitle = title || defaultSeoValues.title,
    ogImage,
    author = defaultSeoValues.author
  } = meta;

  const renderSnippets = () =>
    snippets.map((snippet, index) => (
      <script type='application/ld+json' key={`json-ld-${index}`}>
        {JSON.stringify(snippet)}
      </script>
    ));

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name='robots' content='all' />
      <meta name='description' content={pageDescription} />
      <meta name='keywords' content={pageKeywords} />
      <meta name='author' content={author} />
      {!!ogImage && !!ogImage.url && (
        <meta property='og:image' content={ogImage.url} />
      )}
      <link
        rel='canonical'
        href={`${process.env.NEXT_PUBLIC_URL}${slug || router.asPath}`}
      />
      {noIndex && <meta name='robots' content='noindex' />}
      {snippets.length > 0 && renderSnippets()}
      {/* Facebook Open Graph start */}
      <meta
        content='https://www.facebook.com/URL-bvc'
        property='fb:profile_id'
      />
      <meta content='es' property='og:locale' />
      <meta content='bvc.com.co' property='og:site_name' />
      <meta content='website' property='og:type' />
      <meta
        content='https://bvc.com.co/categoria-1/subcategoria/contenido'
        property='og:URL'
      />
      <meta content='Título SEO o Título de la nota' property='og:title' />
      <meta
        content='Descripción SEO o primeros 160 caracteres del contenido'
        property='og:description'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href={favicon?.image?.url}
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href={favicon?.vectorImage?.url}
      />
      <link rel='manifest' href='/site.webmanifest' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />
      <meta
        content='https://bvc-static.s3.amazonaws.com/URL-image'
        property='og:image'
      />
      <meta content='1200' property='og:image:width' />
      <meta content='630' property='og:image:height' />
      {/* Facebook Open Graph end */}

      {/* Twitter cards start */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@bvc' />
      <meta name='twitter:creator' content='@bvc' />
      <meta name='twitter:title' content='Título SEO o Título del contenido' />
      <meta
        name='twitter:description'
        content='SEO descripción o primeros 160 caracteres'
      />
      <meta
        name='twitter:image'
        content=' https://bvc-static.s3.amazonaws.com/URL-image '
      />
      <meta name='twitter:image:width' content='800' />
      <meta name='twitter:image:height' content='418' />
      {/* Twitter cards end */}
    </Head>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    noIndex: PropTypes.bool,
    ogImage: PropTypes.shape({
      url: PropTypes.string
    }),
    focusKeywords: PropTypes.string,
    author: PropTypes.string
  }),
  snippets: PropTypes.arrayOf(PropTypes.object),
  slug: PropTypes.string,
  favicon: PropTypes.string
};

Seo.defaultProps = {
  title: defaultSeoValues.title,
  meta: {
    metaTitle: defaultSeoValues.title,
    metaDescription: defaultSeoValues.description,
    noIndex: defaultSeoValues.noIndex,
    focusKeywords: defaultSeoValues.keywords,
    author: defaultSeoValues.author
  },
  snippets: [],
  slug: undefined,
  favicon: {}
};

export default Seo;
