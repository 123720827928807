export default `query ModalQuery($locales: [Locale!]!) {
  pdModals(where: { identifier: "login" }, stage: PUBLISHED,  locales: $locales) {
      title
      subtitle
      body {
        text
      }
      links {
        id
        text
        url
      }
  }
}
`;
