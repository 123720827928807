export default `query Derivatives($locales: [Locale!]!) {
  pdPages(where: {identifier: derivatives, isCanvas: false}, locales: $locales, stage: PUBLISHED) {
    content {
      ... on PdSection {
        identifier
        heading
        description
        tab {
          identifier
          description
          summary
          seo {
            metaTitle
            metaDescription
            noIndex
            ogImage {
              fileName
            }
          }
        }
        contentBlock {
          ... on PdBanner {
            title
            description
            buttonText
            buttonUrl
            multimedia {
              fileName
              url
            }
            multimediaSmall {
              fileName
              url
            }
          }
          ... on PdDerivativesContent {
            label
            description
            indexes {
              value: optionalText
              field: fieldIdentifier
              title: description
            }
            captions {
              description
            }
            button {
              text
            }
            showSummaryGraphic
          }
        }
      }
    }
  }
}`;
