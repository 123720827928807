import customCanvasComponents from './canvas-components/customCanvas';

const getCanvas = (params) => {
  return `query getPage($id: ID!, $locales: [Locale!]!) {
  pdPages(where: {id: $id}, locales: $locales, stage: PUBLISHED) {
    identifier
    slug
    title
    locale
    isCanvas
    description {
      html
    }
    content {
      ... on PdSection {
        identifier
        heading
        description
        contentBlock {
          ...on PdCanvasComponent {
            id
            name
            content { ${params.canvasList.map((canvasElement) =>
              customCanvasComponents[canvasElement].replace(
                /(\r\n|\n|\r)/gm,
                ''
              )
            )} }
          }
        }
      }
    }
  }
}`;
};
export default getCanvas;
