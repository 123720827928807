export default `query NoTradingDayQuery($marketLevel: Int, $marketIdentifier: [PdTabIdentifier!],$locales: [Locale!]!) {
  pdNoTradingDays (where: {marketLevel: $marketLevel, marketIdentifier_in: $marketIdentifier}, locales: $locales) {
    noTradingDayTitle
    noTradingDayDescription
    tradingDayTitle
    tradingDayDescription
    marketIdentifier
    marketLevel
  }
}`;
