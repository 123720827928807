export default `
query NewsQuery($first: Int!, $categoryId: ID!, $locales: [Locale!]!) {
  pdNews(where: { category: { id: $categoryId } },
    orderBy: dateAndTime_DESC, stage: PUBLISHED, first: $first, locales: $locales) {
    id
    title
    shortDescription
    author
    dateAndTime
    mainImage {
      url
    }
  }
}
`;
