import seoSerializer from './seo-serializer';
import tooltipSerializer from './tooltip-serializer';
import modalProfileSerializer from './modal-profile-serializer';
import validateField from '@utils/validations';
import { splitWords, sliceAndJoin } from '@utils/strings';
import headerLoginSerializer from './header-login-serializer';

const accessibilityBarSerializer = ({
  languages,
  loginText,
  darkModeText,
  lightModeText,
  fontSizeLetter,
  customerService
}) => ({
  loginButtonText: loginText,
  serviceClient: {
    text: validateField(customerService?.text),
    icon: validateField(customerService?.icon?.url),
    redirectTo: validateField(customerService?.url)
  },
  letterSwitchText: fontSizeLetter,
  languages: languages.map(({ flag, text }) => ({
    icon: flag.url,
    label: text
  })),
  themeModes: { dark: darkModeText, light: lightModeText }
});

const newsletterSerializer = ({
  title,
  inputLabel,
  inputPlaceholder,
  termsAndCondition,
  termsAndConditionsLabel
}) => {
  const tacLabelArray = splitWords(termsAndConditionsLabel);
  return {
    inputLabel,
    inputPlaceholder,
    newsletterText: title,
    termsAndConditionsTitle: termsAndCondition.title,
    normalCheckboxLabel: sliceAndJoin(tacLabelArray, [
      0,
      tacLabelArray.length - 3
    ]),
    underlinedCheckboxLabel: sliceAndJoin(tacLabelArray, [-3]),
    termsAndConditionsContent: termsAndCondition.description.html
  };
};

const quickLinksSerializer = ({ links }) =>
  links.map(({ text, icon, url }) => ({
    text: validateField(text),
    icon: validateField(icon?.url),
    url: validateField(url),
    fileName: validateField(icon?.fileName)
  }));

const serializeContent = ({ identifier, contentBlock }) => {
  switch (identifier) {
    case 'accessibility_bar': {
      return { accessibilityBar: accessibilityBarSerializer(contentBlock[0]) };
    }
    case 'quick_links': {
      return { quickLinks: quickLinksSerializer(contentBlock[0]) };
    }
    case 'newsletter': {
      return { newsletter: newsletterSerializer(contentBlock[0]) };
    }
    case 'footer': {
      return { footer: contentBlock[0] };
    }

    default:
      break;
  }
};

const layoutSerializer = async (request) => {
  const { pdPages, pdSections, pdTooltips, pdHeaderLogins, pdModalProfiles } =
    await request;
  return {
    modalProfile: modalProfileSerializer(pdModalProfiles[0]),
    headerLogin: headerLoginSerializer(pdHeaderLogins[0]),
    tooltip: tooltipSerializer(pdTooltips[0]),
    seo: seoSerializer(pdPages[0]),
    ...pdSections.reduce(
      (finalContent, actualContent) => ({
        ...finalContent,
        ...serializeContent(actualContent)
      }),
      {}
    )
  };
};

export default layoutSerializer;
