import seoSerializer from './seo-serializer';
import validateField from '@utils/validations';
import { identifiersRf } from '@config/constants';
import { dashedCaseToCamel } from '@utils/strings';

const findTab = (tabs = [], identifier) =>
  tabs.findIndex(({ id }) => id === identifier);

const serializeContent = (content) => {
  const filteredData = content.reduce(
    (
      data,
      {
        identifier,
        heading,
        description,
        tab,
        contentBlock = [{}],
        pdLinks = []
      }
    ) => {
      if (identifier === identifiersRf.primary_banner) {
        const [
          {
            multimedia,
            multimediaSmall,
            buttonText,
            buttonUrl,
            title,
            description: descriptionBanner
          }
        ] = contentBlock;

        data.banner_main = {
          title: validateField(title),
          description: validateField(descriptionBanner),
          button: {
            text: validateField(buttonText),
            url: validateField(buttonUrl)
          },
          image: {
            large: validateField(multimedia?.url),
            small: validateField(multimediaSmall?.url)
          }
        };
      }
      if (identifier === identifiersRf.secondary_banner) {
        const [
          {
            multimediaSmall,
            description: descriptionBanner,
            multimedia,
            buttonText,
            buttonUrl,
            title
          }
        ] = contentBlock;
        data[dashedCaseToCamel(identifier)] = {
          title: validateField(title),
          description: validateField(descriptionBanner),
          image: {
            large: validateField(multimedia?.url),
            small: validateField(multimediaSmall?.url)
          },
          button: {
            text: validateField(buttonText),
            url: validateField(buttonUrl)
          }
        };
      }
      if (
        identifier === identifiersRf.information_operations ||
        identifier === identifiersRf.information_operations_contado ||
        identifier === identifiersRf.information_operations_repo ||
        identifier === identifiersRf.information_operations_simultaneas ||
        identifier === identifiersRf.information_operations_ttv ||
        identifier === identifiersRf.historical ||
        identifier === identifiersRf.summary ||
        identifier === identifiersRf.information_title ||
        identifier === identifiersRf.prices_and_volumes_price ||
        identifier === identifiersRf.prices_and_volumes_rate
      ) {
        const {
          pdPopup,
          description: descriptionTab,
          identifier: tabIdentifier,
          seo
        } = tab;
        const indexTab = findTab(data.tabs, tabIdentifier);
        const logos =
          identifier === identifiersRf.issuer_information
            ? content.find(
                ({ identifier: id }) => id === identifiersRf.info_participant
              )?.contentBlock?.pdGeneralInformationLogos
            : pdLinks;
        const financialInformationLogos =
          identifier === identifiersRf.financial_information
            ? content.find(
                ({ identifier: id }) => id === identifiersRf.info_participant
              )?.contentBlock?.pdFinancialInformationLogos
            : pdLinks;
        const sectionContent = contentBlock[0];
        const section = {
          title: validateField(heading),
          subTitle: validateField(sectionContent?.subTitle),
          description: validateField(description),
          secondaryDescription: validateField(
            sectionContent?.secondaryDescription
          ),
          showSummaryGraphic: validateField(sectionContent?.showSummaryGraphic),
          captions: validateField(
            sectionContent?.pdCaptions?.map(
              ({ description: captionDescription }) => captionDescription
            )
          ),
          buttonText: validateField(sectionContent?.buttonText),
          content: contentBlock,
          indices: sectionContent?.pdIndices,
          logos,
          financialInformationLogos
        };
        indexTab >= 0
          ? (data.tabs[indexTab].sections[identifier] = section)
          : data.tabs.push({
              id: tabIdentifier,
              title: descriptionTab,
              sections: { [identifier]: section },
              pdPopup,
              seo: seoSerializer({ seo })
            });
      }
      return data;
    },
    { tabs: [] }
  );

  return filteredData;
};

const fixedRentSerializer = async (request) => {
  const { pdPages } = await request;
  const [{ content }] = pdPages;
  return serializeContent(content);
};

export default fixedRentSerializer;
