import seoSerializer from './seo-serializer';

const reportAndBulletinCategoriesSerializer = async (request) => {
  const { pdReportsAndBulletinsCategories } = await request;
  return pdReportsAndBulletinsCategories.map((category) => ({
    id: category.id,
    indentifier: category.indentifier,
    name: category.name,
    seo: seoSerializer({ seo: category?.seo }),
    subCategories: (category?.subCategories || []).map(
      ({ seo: subCategorySeo, ...subCategory }) => ({
        ...subCategory,
        seo: seoSerializer({ seo: subCategorySeo })
      })
    )
  }));
};

export default reportAndBulletinCategoriesSerializer;
