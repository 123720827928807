import validateField from '@utils/validations';

export const serializeContent = ({ identifier, contentBlock }) => {
  if (!contentBlock.length) return {};
  switch (identifier) {
    case 'issuers_list_global_market_primary_banner': {
      const { title, multimedia, description } = contentBlock[0];
      return {
        primaryBanner: {
          title: validateField(title),
          description: validateField(description),
          image: validateField(multimedia?.url)
        }
      };
    }
    case 'issuers_list_global_market_secondary_banner': {
      const { title, multimedia, description, buttonText, buttonUrl } =
        contentBlock[0];
      return {
        secondaryBanner: {
          title: validateField(title),
          description: validateField(description),
          image: validateField(multimedia?.url),
          button: {
            text: validateField(buttonText),
            url: validateField(buttonUrl)
          }
        }
      };
    }

    default: {
      return { [identifier]: validateField(contentBlock) };
    }
  }
};

const issuersListSerializer = async (request) => {
  const { pdPages } = (await request) || {};
  const page = pdPages[0];
  const content = page?.content?.reduce(
    (finalContent, actualContent) => ({
      ...finalContent,
      ...serializeContent(actualContent)
    }),
    {}
  );
  return content;
};

export default issuersListSerializer;
