export default `query getCanvasList($id: ID!, $locales: [Locale!]!) {
  pdPages(where: {id: $id}, locales: $locales, stage: PUBLISHED) {
      content {
        ... on PdSection {
          contentBlock {
            ... on PdCanvasComponent {
              name
            }
          }
        }
      } 
  }
}`;
