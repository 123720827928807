import validateField from '@utils/validations';

const reportByCategoryAndDateSerializer = async (request) => {
  const { pdReportsAndBulletins = [] } = await request;
  return pdReportsAndBulletins.map((report) => ({
    date: validateField(report?.date),
    attached: {
      fileName: validateField(report?.attached?.fileName),
      url: validateField(report?.attached?.url)
    },
    title: validateField(report?.title),
    description: validateField(report.description)
  }));
};

export default reportByCategoryAndDateSerializer;
