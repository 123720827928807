export default `query TabsQuery($marketLevel: String, $locales: [Locale!]!){
  pdTabs (where: {marketLevel: $marketLevel}, locales: $locales) {
    identifier
    description
    generalDescription
    marketLevel
    indicesHighlightables {
			... on PdIndex {
          description
          fieldIdentifier
      }
    }
    seo {
      metaTitle
      metaDescription
      noIndex
      ogImage {
        fileName
      }
    }
    subTabs {
      identifier
      description
      summary
      seo {
        metaTitle
        metaDescription
        noIndex
        ogImage {
          fileName
        }
      }
      indices {
        description
        fieldIdentifier
        isHiddenField
      }
      captions {
        description
        summary
        logo {
          icon {
            fileName
            url
          }
          url
        }
      }
      button {
        text
        url
      }
    }
  }
}
`;
