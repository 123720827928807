import validateField from '@utils/validations';
import { sortArrayByDate } from '@utils/arrays';

const serializeContent = (content) => {
  const banner = content.find(({ identifier }) => identifier === 'banner');
  const downloadable = content.find(
    ({ identifier }) => identifier === 'downloadable'
  );
  const serializedContent = {};

  if (banner) {
    const [{ titleBanner, multimedia, multimediaSmall, descriptionBanner }] =
      banner.contentBlock;
    serializedContent.banner = {
      title: validateField(titleBanner),
      description: validateField(descriptionBanner),
      variableImage: {
        desktop: validateField(multimedia?.url),
        mobile: validateField(multimediaSmall?.url)
      }
    };
  }
  if (downloadable) {
    serializedContent.downloadable = {
      title: validateField(downloadable?.heading),
      cards: sortArrayByDate(downloadable?.contentBlock)
    };
  }

  return serializedContent;
};

const nonTradingDaysPageSerializer = async (request) => {
  const { pdPages } = await request;
  const [{ description, content }] = pdPages;

  return {
    description: validateField(description?.text),
    sections: serializeContent(content)
  };
};

export default nonTradingDaysPageSerializer;
