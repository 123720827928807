const getSlugs = ({ isCanvas = null }) => {
  const filter = isCanvas !== null ? `where: {isCanvas: ${isCanvas}},` : '';
  return `
query getSlugs {
  pdPages(${filter} stage: PUBLISHED first: 1000) {
    localizations(includeCurrent: true) {
      id
      slug
      locale
    }
  }
}
`;
};

export default getSlugs;
