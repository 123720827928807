const tooltipSerializer = (payload) => {
  const {
    title,
    description,
    icon: { url },
    leftBtnTitle,
    rightBtnTitle,
    headerTitle
  } = payload;
  return { title, description, url, leftBtnTitle, rightBtnTitle, headerTitle };
};

export default tooltipSerializer;
