export default `
  pdTooltips(locales: $locales) {
    title
    description
    icon {
      id,
      url
    }
    leftBtnTitle
    rightBtnTitle
    headerTitle
  }
`;
