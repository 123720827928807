import validateField from '@utils/validations';

const learnSerializer = async (request) => {
  const { pdPages } = await request;
  return pdPages.map((page) => {
    const {
      identifier,
      slug,
      title,
      description,
      answer = [],
      buttons = []
    } = page || {};

    return {
      identifier: validateField(identifier),
      slug: validateField(slug),
      title: validateField(title),
      description: validateField(description?.text),
      answer: validateField(answer[0]?.text),
      buttons
    };
  })[0];
};

export default learnSerializer;
