import validateField from '@utils/validations';
import { sortArrayByDate } from '@utils/arrays';

const financialInformationByCategoryAndDateSerializer = async (request) => {
  const { pdFinancialInformations = [] } = await request;
  return sortArrayByDate(pdFinancialInformations).map((report) => ({
    id: validateField(report?.id),
    date: validateField(report?.date),
    attached: {
      id: validateField(report?.attached?.id),
      fileName: validateField(report?.attached?.fileName),
      url: validateField(report?.attached?.url)
    },
    title: validateField(report?.title),
    description: validateField(report.description)
  }));
};

export default financialInformationByCategoryAndDateSerializer;
