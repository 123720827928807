import numeral from 'numeral';
import currency from 'currency.js';

export const isAMultipleOf = (base, numberToValidate) =>
  numberToValidate % base === 0;

export const formatPercentage = (value, decimals = 0) =>
  numeral(value).format(decimals === 0 ? '0%' : `0.${'0'.repeat(decimals)}%`);

export const roundToTwo = (num) => {
  const numString = num && num.toString();
  const lengthDecimal =
    numString && numString.includes('.') ? numString.split('.')[1].length : 1;
  const decimal = num && parseFloat(num);
  const m = Number((Math.abs(decimal) * 100).toPrecision(15));
  const valueToDecimals = (Math.round(m) / 100) * Math.sign(decimal);
  return { decimals: lengthDecimal, value: valueToDecimals };
};
export const generalFormatDecimal = (value, options) => {
  if (value !== null && value !== undefined) {
    let numToString;
    let splitDecimals;
    if (value !== 0) {
      numToString = value && value.toString();
      splitDecimals = numToString.split('.');
    }

    const {
      separator = ',',
      symbol = '',
      decimal = '.',
      decimals = 0
    } = options;

    if (decimals === null && splitDecimals[1]) {
      const precision = splitDecimals[1].length;
      return currency(Number(value), {
        separator,
        symbol,
        precision
      }).format();
    } else {
      return currency(Number(value), {
        separator,
        symbol,
        decimal,
        precision: decimals
      }).format();
    }
  }
  return value;
};

export const formatDecimal = (
  value,
  { separator = ',', symbol = '', decimal = '.', decimals = 0 } = {
    separator: ',',
    symbol: '',
    decimal: '.',
    decimals: 0,
    isVolume: false
  }
) => {
  let numToString;
  let splitDecimals;
  if (value) {
    numToString = value && value.toString();
    splitDecimals = numToString.split('.');
    const customFormat = () => {
      const decimalNumber = splitDecimals[1].substring(0, 2);
      const withoutDecimal =
        splitDecimals[0] === '-0'
          ? splitDecimals[0]
          : currency(Number(splitDecimals[0]), {
              separator,
              symbol,
              decimal
            }).format();
      const splitWithoutDecimal = withoutDecimal.split('.');
      // Only return two decimals
      return `${splitWithoutDecimal[0]}.${decimalNumber}`;
    };
    /* If there is more of two decimal, force the number two only
       TODO: If there a issue with decimal, check this validation
    */
    if (
      numToString.includes(decimal) &&
      splitDecimals[1].length > 2 &&
      decimals === 2
    ) {
      return value !== null && value !== undefined
        ? currency(Number(value), {
            separator,
            symbol,
            decimal,
            precision: decimals,
            format: () => customFormat()
          }).format()
        : value;
    }
  }
  return generalFormatDecimal(value, {
    separator,
    symbol,
    decimal,
    decimals
  });
};

export const isEven = (number) => isAMultipleOf(2, number);

export const isOdd = (number) => !isEven(number);
